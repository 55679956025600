import React, { Fragment, useState, useEffect} from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
    Container,
    Row,
    Col,
    Card,
    Table,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label,
} from "reactstrap";
import { toast } from "react-toastify";
import axios from "axios";
import Select from "react-select";
import  secureLocalStorage  from  "react-secure-storage";

const Assign = () => {

    const baseurl = "https://d3c9ouasuy8pg6.cloudf.almaxyra.ma";
    const [VaryingContentone, setVaryingContentone] = useState(false);
    const [client,setClient] = useState(" ");
    const [produit,setProduit] = useState(" ");
    const [id, setId] = useState();

    const [modif, setModif] =useState(true);

    const [selected , setSelected] = useState(true);

    const [Clients, setClients] = useState([]);
    const [Produits, setProduits] = useState([]);
    const [Assigns, setAssigns] = useState([]);
    const t  = secureLocalStorage.getItem('token');
    const headers = {'x-access-token': t}

    function deleteitem(id,e){
      axios.delete(baseurl+"/api/v1/assign/"+id,{headers:headers})
      .then((response)=>{
        getData();
        Verticalcentermodaltoggle(e);
      });
    }

    function VaryingContentonetogglex(idproduct,idclient,id) {
     
      setModif(true);
      setVaryingContentone(!VaryingContentone);
      setProduit(idproduct);
      setClient(idclient);
      setId(id);
      //console.log(state);
    }

    const VaryingContentonetoggle = () =>{
      setVaryingContentone(!VaryingContentone);
      setModif(false);
      setProduit(" ");
      setClient(" ");
      setSelected(true);
    }
    
    const [Verticalcenter, setVerticalcenter] = useState(-1);
    function Verticalcentermodaltoggle(index) {
      setVerticalcenter(index);
    }

    function handleChangeClient(evt) {
      setClient(evt.value);

      if(produit !== " "){
        setSelected(false);
      }
    }
    function handleChangeProduit(evt) {
      setProduit(evt.value);

      
      if(client !== " "){
        setSelected(false);
      }
    }
  const handleSubmit = () => {
    //reqres registered sample user
     const user = secureLocalStorage.getItem('uid');
     const today = new Date();

    const loginPayload = {
      idproduct : produit,
      idclient : client,
      user:user,
      date: today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
    }

  axios.post(baseurl+"/api/v1//add/assign", loginPayload)
      .then(response => {
        if(response.data.affectedRows === 1){
          toast.success("Le produit est bien assigné !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          getData();
          VaryingContentonetoggle();
        }else{
            toast.error("Le produit est déjà assigné à ce client !", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1500,
            });
        }
     })
      .catch(err =>  {
        toast.error("Une erreur sur le serveur, Veuillez réssayer !", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1500,
      });
      // console.log(err);
    });
  };

  function getData(){  
    axios.get(baseurl+"/api/v1/assign")
    .then((response)=>{
      setAssigns(response.data);
    });
  }

  function getProducts(){
    const t  = secureLocalStorage.getItem('token');
    const headers = {'x-access-token': t}

    axios.get(baseurl+"/api/v1/products",{headers:headers})
    .then((response)=>{
      let prds = [];
      response.data.forEach((item) => {
        prds.push({value:item.uuid,label: item.marque+' - '+item.designation});
      });
      setProduits(prds);
    });
  }

  function getClients(){
    axios.get(baseurl+"/api/v1/clients")
    .then((response)=>{
      let cls = [];
      response.data.forEach((item) => {
        cls.push({value:item.uuid,label: item.name});
      });
      setClients(cls);
    });
  }

  function modifyitem(){
    const today = new Date();
    const loginPayload = {
      id: id,
      idproduct:produit,
      idclient:client,
      date: today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
    }

      axios.patch(baseurl+"/api/v1/update/assign", loginPayload,{headers:headers})
      .then(response => {
        if(response.data.affectedRows === 1){
          toast.success("Affectation a été bien modifé !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          getData();
          VaryingContentonetoggle();
        }else{
            toast.error("Erreur est survenue !", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1500,
            });
            // console.log(response);
        }
     })
      .catch(err =>  {
      // console.log(err.response)
       });
  }

  useEffect(() => {

    getData();
    getProducts();
    getClients();
}, [])

  return (
    <Fragment>
    <Breadcrumb parent="Admin" title="Assigner un Produit" />
    <Container fluid={true}>
      <Row className="p-20">
        <Button
          onClick={VaryingContentonetoggle}
          className="btn btn-air-primary"
          outline
          color="primary"
        >
          {" "}
          <i className="icon-plus"></i> {"Assigner un Produit"}
        </Button>

        <Modal isOpen={VaryingContentone} toggle={VaryingContentonetoggle}>
          <ModalHeader toggle={VaryingContentonetoggle}>
            {"Assigner un Produit"}
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label className="col-form-label" for="recipient-name">
                  {"Produit"}
                </Label>
              <Select options={Produits} onChange={handleChangeProduit} value = {Produits.filter(prod => prod.value === produit)}/>
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label" for="recipient-name">
                  {"Client"}
                </Label>
                <Select options={Clients} onChange={handleChangeClient} value = {Clients.filter(prod => prod.value === client)} isDisabled={modif} />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={VaryingContentonetoggle}>
              {"Fermer"}
            </Button>
             <Button color="primary" disabled={selected} onClick={modif ? modifyitem : handleSubmit} >
              {modif? "Modifier" : "Ajouter"}
            </Button>
          </ModalFooter>
        </Modal>
      </Row>

      <Row>
        <Col sm="12">
          <Card>
            <div className="table-responsive">
              <Table className="table-border-horizontal">
                <thead>
                  <tr>
                    <th className="text-center">{"#"}</th>
                    <th className="text-center">{"Product"}</th>
                    <th className="text-center">{"Client"}</th>
                    <th className="text-center">{"Date"}</th>
                    <th className="text-center">{"Action"}</th>
                  </tr>
                </thead>
                <tbody>
                  {Assigns.map((cl, index) => (
                    <tr key={index} data-index={index}>
                      <th className="text-center" scope="row">{index+1}</th>
                      <td className="text-center">{cl.designation}</td>
                      <td className="text-center">{cl.name} </td>
                      <td className="text-center">{cl.created.split('T')[0]}</td>
                      <td className="text-center">
                      <button className="btn btn-edit" style={{color:'#49b85a'}} onClick={()=>VaryingContentonetogglex(cl.idproduct,cl.idclient,cl.id)}>
                        <i className="icon-pencil-alt"></i>
                      </button>
                      <button className="btn btn-edit" style={{color:'#f93e3e'}}  onClick={()=>Verticalcentermodaltoggle(index)}>
                        <i className="icon-trash"></i>
                      </button>
                      <Modal
                              isOpen={Verticalcenter === index}
                              toggle={Verticalcentermodaltoggle}
                              centered
                            >
                              <ModalHeader toggle={Verticalcentermodaltoggle}>
                                {"Confirmez-vous la suppression ?"}
                              </ModalHeader>
                              <ModalBody>
                                <p style={{textAlign:'center',marginLeft: '70px',marginRight:'70px', marginTop:'10px'}}>
                                  {
                                    "Si vous confirmez, le liaison sera définitivement supprimée de la base"
                                  }
                                </p>
                              </ModalBody>
                              <ModalFooter>
                              <Button
                                  color="primary"
                                  onClick={(e) => {
                                    deleteitem(cl.id,e);
                                  }}
                                >
                                  {"Je confirme"}
                                </Button>

                                <Button
                                  color="secondary"
                                  onClick={Verticalcentermodaltoggle}
                                >
                                  {"Annuler"}
                                </Button>
                                
                              </ModalFooter>
                            </Modal>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Card>
        </Col>
      </Row>
     
    </Container>
  </Fragment>
  );
};

export default Assign;
