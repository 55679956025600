import React, { Fragment } from 'react';
import { useState,useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap'
const Footer = (props) => {

  const [side, SetSide]=useState(false);

  useEffect(() => {
    const admin = window.location.pathname.split('/')[1];
    if(admin === 'admin'){
      SetSide(true);
    }
  }, []);
  return (
    <Fragment>
      <footer className={side ? 'footer-side footer':'footer'} style={{position:'relative'}}>
        <Container fluid={true}>
          <Row>
            <Col md="12" className="footer-copyright text-center">
              <p className="mb-0">{"Copyright 2023 © Almaxyra & Company"}</p>
            </Col>
          </Row>
        </Container>
      </footer>
    </Fragment>
  );
}

export default Footer;