import React, { Fragment,useState,useEffect } from 'react'
import ReactDOM from 'react-dom';
import './index.scss';
import {BrowserRouter,Switch,Route,Redirect} from 'react-router-dom'
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import App from './components/app'
import { CSSTransition,TransitionGroup } from 'react-transition-group'
import {routes} from './route/routes';
import {routesAd} from './route/routesAd'
import store from './store'

import ConfigDB  from './data/customizer/config'


// Signin page
import LoginWithBgImage from './auth/login'
import  secureLocalStorage  from  "react-secure-storage";
import Contact from './components/AlmaFront/contacter';



const Root = (props) =>  {
  const [anim, setAnim] = useState("");
  const [Admin, setAdmin] = useState(false);
  const animation = localStorage.getItem("animation") || ConfigDB.data.router_animation || 'fade' 
  const abortController = new AbortController();

  const jwt_token = secureLocalStorage.getItem('token'); 
  const [url, setUrl] = useState(false);


  useEffect(() => {

      setAnim(animation)
      //console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
      //console.disableYellowBox = true;

      secureLocalStorage.getItem('trole') === 1 ? setAdmin(true) : setAdmin(false)
      setAdmin(true);

      const pat = window.location.pathname.split('/')[1];
  
      if(pat ==='contact'){
        setUrl(true);
      }else{
        setUrl(false);
      }
      return function cleanup() {
          abortController.abort();
      }

      // eslint-disable-next-line 
    }, []);

  

    return(
      <Fragment>
        <Provider store={store}>
        <BrowserRouter basename={`/`}>
        <Switch>
          <Route  path={`/login`} component={LoginWithBgImage} />
          <Route  path={`/contacter`} component={Contact} />
          {jwt_token  ?
          <App>
          <Route exact path={`/`} render={() => {
              return (<Redirect to={`/`} />)
          }} /> 
          <TransitionGroup>
            {Admin ?
               routesAd.map(({ path, Component }) => (
                <Route key={path}  exact  path={`${path}`}>
                    {({ match }) => (
                        <CSSTransition 
                          in={match != null}
                          timeout={100}
                          classNames={anim} 
                          unmountOnExit>
                          <div><Component/></div>
                        </CSSTransition> 
                    )}
                </Route>
                ))
              : 
              routes.map(({ path, Component }) => (
                <Route key={path}  exact  path={`${path}`}>
                    {({ match }) => (
                        <CSSTransition 
                          in={match != null}
                          timeout={100}
                          classNames={anim} 
                          unmountOnExit>
                          <div><Component/></div>
                        </CSSTransition> 
                    )}
                </Route>
                ))
              }
             
          </TransitionGroup> 
          
          </App>
          :
          url ? 
          <Redirect to={`/contact`} />
          :
          <Redirect to={`/login`} />
         }      
        </Switch>
        </BrowserRouter>
        </Provider>
        {/* </Auth0Provider> */}
      </Fragment>
      )
}

ReactDOM.render(<Root/>,
  document.getElementById('root')
);
serviceWorker.unregister();
