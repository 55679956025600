import React, { Fragment,useState,useEffect } from "react";
import Breadcrumb from "../../layout/breadcrumb";
//import { Document, Page } from 'react-pdf';
import Dropzone from 'react-dropzone'

import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import Select from "react-select";
//import Dropzone from 'react-dropzone-uploader'
import { toast } from "react-toastify";
import axios from "axios"
import { v4 as uuidv4 } from 'uuid';
import  secureLocalStorage  from  "react-secure-storage";


const Documents = () => {
  const baseurl = "https://d3c9ouasuy8pg6.cloudf.almaxyra.ma";

  const [Jos, setJo] = useState([]);
  const [modif, setModif] =useState(false);
  const [uploadedtime, setuploadedtime] = useState(0)
  const [VaryingContentone, setVaryingContentone] = useState(false);
  const [dis, setDis] = useState(true);

  const VaryingContentonetoggle = () =>{
   setVaryingContentone(!VaryingContentone);
   setModif(false);
   setState({
    titre:"",
    description:"",
  })
  setCatg("");
  setProduit("");
  }

  const [Verticalcenter, setVerticalcenter] = useState(-1);
  function Verticalcentermodaltoggle(index) {
    setVerticalcenter(index);
  }
  function VaryingContentonetogglex(title,pr,cat,description,uuid) {
    setState({
      titre:title,
      description:description,
    })
    setProduit(pr);
    setCatg(cat);
    setUuid(uuid);
    setModif(true);
    setVaryingContentone(!VaryingContentone);
  }

  const t  = secureLocalStorage.getItem('token');
  const headers = {'x-access-token': t}

  const [Produits, setProduits] = useState([]);
  const [Categories, setCategories] = useState([]);
  const [produit,setProduit] = useState(null);
  const [catg,setCatg] = useState(null);
  const [files, setFiles] = useState([]);
  const [uuid,setUuid] = useState("");

  function handleChangeProduit(evt) {
    setProduit(evt.value);
  }

  function handleChangeCatg(evt) {
    setCatg(evt.value);
  }

  
  const [state, setState] = React.useState({
    titre:"",
    description:"",
  })


  function deleteitem(id,file){
    axios.delete(baseurl+"/api/v1/docs/"+id+"/"+file,{headers:headers})
    .then((response)=>{
      Verticalcentermodaltoggle();
      getData();
    });
  }

  /*const handleChangeStatus = ({ meta, file }, status) => { 

    if(status==='done'){
      setFiles(file);
    }

  }

  const getUploadParams = ({ meta }) => { 
    return { url: 'https://httpbin.org/post'} 
  }*/


  /*const handleSubmit = async (files) => {
   const body = new FormData()
    
        body.append('docs', files,files.name);
  
    try {
      const response = await axios.post(baseurl+"/api/v1/upload/docs", body);
      submit(response.data);
      //console.log("RESPONSE = ",response.data);
    } catch (error) {
      //console.log(error);
      return "API error.";
    }
  }
*/
const uploadFiles = (acceptedFiles) =>{
      setFiles(acceptedFiles);
      setDis(false);
}

const acceptedFilesItems = files.map(file => (
  <li key={file.path}>
    {file.path}
  </li>
));

  const submit = async () => {
    //reqres registered sample user
     const user = secureLocalStorage.getItem('uid');
     const today = new Date();
     const body = new FormData()

    body.append('docs', files[0],files[0].name);

    try {
    const response = await axios.post(baseurl+"/api/v1/upload/docs", body,{
      onUploadProgress : (data) =>{
        setuploadedtime(Math.round((data.loaded / data.total)*100));
      }
    });

    const loginPayload = {
      uuid:uuidv4(),
      idproduct:produit,
      idcategorie:catg,
      title:state.titre,
      description:state.description,
      document:response.data,
      user:user,
      date: today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
    }

    axios.post(baseurl+"/api/v1/add/docs", loginPayload,{headers:headers})
    .then(response => {
      //get token from response
     // console.log(response);
      if(response.data.affectedRows === 1){
        toast.success("Document a été bien enregistré !", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        getData();
        VaryingContentonetoggle();
      }else{
          toast.error("Document existe Déjà !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1500,
          });
      }
        })
          .catch(err => {
            //console.log(err)
      });
    } catch (error) {
    //console.log(error);
    return "API error.";
    }
 
  };


  function handleChange(evt) {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value
    });
  }

  function getProducts(){
    const t  = secureLocalStorage.getItem('token');
    const headers = {'x-access-token': t}

    axios.get(baseurl+"/api/v1/products",{headers:headers})
    .then((response)=>{
      let prds = [];
      response.data.forEach((item) => {
        prds.push({value:item.uuid,label: item.marque+' - '+item.designation});
      });
      setProduits(prds);
    });
  }

  function getCategories(){
    axios.get(baseurl+"/api/v1/categories")
    .then((response)=>{
      let ctgs = [];
      response.data.forEach((item) => {
        ctgs.push({value:item.uuid,label:item.designation});
      });

      setCategories(ctgs);
    });
}

  function getData(){
    axios.get(baseurl+"/api/v1/documents")
    .then((response)=>{
      setJo(response.data);
    });
  }

  function modifyitem(){
    const today = new Date();

    const loginPayload = {
    uuid: uuid,
    idproduct:produit,
    idcategorie:catg,
    title:state.titre,
    description:state.description,
    date: today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
    }
    
      axios.patch(baseurl+"/api/v1/update/docs", loginPayload,{headers:headers})
      .then(response => {
        if(response.data.affectedRows === 1){
          toast.success("Document a été bien modifé !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          getData();
          VaryingContentonetoggle();
        }else{
            toast.error("Erreur est survenue !", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1500,
            });
            // console.log(response);
        }
     })
      .catch(err =>  {
      // console.log(err.response)
     });
  }

useEffect(() => {
    const admin = window.location.pathname.split('/')[2];
    if(admin === 'add'){
      VaryingContentonetoggle();
    }
    getData();
    getProducts();
    getCategories();
      // eslint-disable-next-line react-hooks/exhaustive-deps
 },[])
  return (
    <Fragment>
    <Breadcrumb parent="Admin" title="Gestion des Documents" />
    <Container fluid={true}>
      <Row className="p-20">
        <Button
          onClick={VaryingContentonetoggle}
          className="btn btn-air-primary"
          outline
          color="primary"
        >
          {" "}
          <i className="icon-plus"></i> {" Ajouter un Document"}
        </Button>
        <Modal isOpen={VaryingContentone} toggle={VaryingContentonetoggle} size='lg'>
          <ModalHeader toggle={VaryingContentonetoggle}>
            {"Ajouter un Document"}
          </ModalHeader>
          <ModalBody>
            <Form>
            <Row>
              <div className="col-md-6">
              <FormGroup>
                <Label className="col-form-label" for="product">
                  {"Produit"}
                </Label>
                <Select options={Produits} onChange={handleChangeProduit}  value={Produits.filter(prod => prod.value === produit)} />
              </FormGroup>
              </div>
              <div className="col-md-6">
              <FormGroup>
                <Label className="col-form-label" for="catg">
                  {"Catégorie"}
                </Label>
                <Select options={Categories} onChange={handleChangeCatg} value={Categories.filter(catx => catx.value === catg)} />
              </FormGroup>
              </div>
             </Row>
             <Row>
              <div className="col-md-12">
              <FormGroup>
                <Label className="col-form-label" for="recipient-name">
                  {"Titre du Document"}
                </Label>
                <Input
                    type="text"
                    name="titre"
                    value={state.titre}
                    onChange={handleChange}
                  />
              </FormGroup>
              </div>
              <div className="col-md-12">
              <FormGroup>
                <Label className="col-form-label" for="recipient-name">
                  {"Description du Document"}
                </Label>
                <textarea className="form-control" value={state.description} name="description" onChange={handleChange} rows="5" cols="5" ></textarea>
              </FormGroup>
              </div>
             </Row>
             <Row>
              <div className="col-md-12">
              { modif ? <p></p> :
              <FormGroup>
                <Label>Déposer un Document</Label>
                            {/*  <Dropzone
                                    getUploadParams={getUploadParams}
                                    onChangeStatus={handleChangeStatus}
                                    //onSubmit={handleSubmit}
                                    accept="video/*,application/pdf"
                                    maxFiles={1}
                                    multiple={false}
                                    //canCancel={true}
                                    inputContent="Déposer un Fichier"
                                    styles={{
                                        dropzone: { width: '100%', height: '180px' },
                                        dropzoneActive: { borderColor: 'green' },
                                    }}
                              /> */} 

                     <Dropzone maxFiles={1}  onDrop={(acceptedFiles) => uploadFiles(acceptedFiles)} >
                          {({getRootProps, getInputProps}) => (
                            <section className="dragnddrop">
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <p>Déposer un fichier  (Un seul fichier)</p> 
                                {acceptedFilesItems}
                              </div>
                            </section>
                          )}
                      </Dropzone>
                      
                 <div className="progress mt-2">
                    <div className="progress-bar" role="progressbar"  aria-valuemax="100"
                    aria-valuenow={uploadedtime} aria-valuemin="0" style={{width: `${uploadedtime}%`}}>
                        {`${uploadedtime}%`}
                    </div>
                </div>
              </FormGroup>
             
             }
              </div>
             </Row>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={VaryingContentonetoggle}>
              {"Fermer"}
            </Button>
            <Button color="primary" disabled={dis} onClick={modif ? modifyitem : submit} >
              {modif? "Modifier" : "Ajouter"}
            </Button>
          </ModalFooter>
        </Modal>
      </Row>

      <Row>
        <Col sm="12">
          <Card>
            <div className="table-responsive">
              <Table className="table-border-horizontal">
                <thead>
                  <tr>
                    <th>{"#"}</th>
                    <th className="col-md-4">{"Title"}</th>
                    <th >{"Produit"}</th>
                    <th >{"Catégorie"}</th>
                    <th >{"Document"}</th>
                    <th>{"Date"}</th>
                    <th>{"Action"}</th>
                  </tr>
                </thead>
                <tbody>
                  {Jos.map((doc, index) => (
                    <tr key={index} data-index={index}>
                      <th scope="row">{index+1}</th>
                      <td>{doc.title}</td>
                      <td>{doc.pr}</td>
                      <td>{doc.cat}</td>
                      <td className="text-center"><a target="_blank" rel="noopener noreferrer" href={baseurl+"/getdocs/"+doc.document}>Lien</a></td>
                      <td>{doc.created.split("T")[0]}</td>
                      <td> <button className="btn btn-edit" style={{color:'#49b85a'}} onClick={()=>VaryingContentonetogglex(doc.title,doc.idproduct,doc.idcategorie,doc.description,doc.uuid)}>
                        <i className="icon-pencil-alt"></i>
                      </button>
                      <button className="btn btn-edit" style={{color:'#f93e3e'}} onClick={()=>Verticalcentermodaltoggle(index)}>
                        <i className="icon-trash"></i>
                      </button>
                      <Modal
                              isOpen={Verticalcenter === index}
                              toggle={Verticalcentermodaltoggle}
                              centered
                            >
                              <ModalHeader toggle={Verticalcentermodaltoggle}>
                                {"Confirmez-vous la suppression ?"}
                              </ModalHeader>
                              <ModalBody>
                                <p style={{textAlign:'center',marginLeft: '70px',marginRight:'70px', marginTop:'10px'}}>
                                  {
                                    "Si vous confirmez, le document sera définitivement supprimé de la base"
                                  }
                                </p>
                              </ModalBody>
                              <ModalFooter>
                              <Button
                                  color="primary"
                                  onClick={(e) => {
                                    deleteitem(doc.uuid,doc.document);
                                  }}
                                >
                                  {"Je confirme"}
                                </Button>

                                <Button
                                  color="secondary"
                                  onClick={Verticalcentermodaltoggle}
                                >
                                  {"Annuler"}
                                </Button>
                                
                              </ModalFooter>
                            </Modal>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Card>
        </Col>
      </Row>

    </Container>
  </Fragment>
  );
};

export default Documents;
