import React, { Fragment, useState} from 'react';
import Loader from "../layout/loader";
import Taptop from "../layout/tap-top";
import Header from '../layout/header'
import Sidebar from '../layout/sidebar'
import Footer from '../layout/footer'
import {ToastContainer} from 'react-toastify'
import {withRouter} from 'react-router-dom'
import { useEffect } from 'react';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';

const App = ({children}) => {
  const [side,SetSide]=useState(false);

  useEffect(() => {
    const admin = window.location.pathname.split('/')[1];
    if(admin === 'admin'){
      SetSide(true);
    }

  }, []);

  disableReactDevTools();

  return (
    <Fragment>
      <Loader/>
      <Taptop/>
      <div className="page-wrapper compact-wrapper" id="pageWrapper">
        <Header/>
        <div className="page-body-wrapper" >
          {side ? <Sidebar/> :''}
          <div className={side ? "page-body margL" :"page-body"}>
            {children}
          </div>
          <Footer/>
        </div>
      
      </div>
      <ToastContainer/>
    </Fragment>
  );
}
export default withRouter(App);