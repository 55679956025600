import Contact from '../components/AlmaFront/contacter'
import Dashboard from '../components/AlmaBack/dashboard'
import Clients from '../components/AlmaBack/clients'
import Products from '../components/AlmaBack/products'
import Categories from '../components/AlmaBack/categories'
import Documents from '../components/AlmaBack/documents'
import LoginWithBgImage from '../auth/login'
import Assign from '../components/AlmaBack/assign'
import ProductShow from '../components/AlmaBack/productShow'
import Forgetpwd from '../auth/forgotpsswd'
import RestaurerProduct from '../components/AlmaBack/restaurerProduct'
import Overview from '../components/AlmaBack/overview'
import Specs from '../components/AlmaBack/specs'
import RestaurerCategorie from '../components/AlmaBack/restaurerCatgorie'
import RestaurerClient from '../components/AlmaBack/restaurerClient'

export const routesAd = [

        { path:"/contact", Component:Contact},
        { path:"/admin/dashboard", Component:Dashboard},
        { path:"/admin/add/client", Component:Clients},
        { path:"/admin/clients", Component:Clients},
        { path:"/admin/assign", Component:Assign},
        { path:"/admin/add/product", Component:Products},
        { path:"/admin/products", Component:Products},
        { path:"/admin/add/categorie", Component:Categories},
        { path:"/admin/categories", Component:Categories},
        { path:"/admin/add/document", Component:Documents},
        { path:"/admin/documents", Component:Documents},
        { path:"/admin/frontend/:id", Component:ProductShow},
        { path:"/admin/overview", Component:Overview},
        { path:"/admin/specs", Component:Specs},
        { path:"/admin/restore/product", Component:RestaurerProduct},
        { path:"/admin/restore/client", Component:RestaurerClient},
        { path:"/admin/restore/categorie", Component:RestaurerCategorie},
        { path:"/login", Component:LoginWithBgImage},
        { path:"/forgot", Component:Forgetpwd},
]
