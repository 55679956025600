import React, { Fragment, useState,useEffect,useCallback} from "react";
import Breadcrumb from "../../layout/breadcrumb";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import { toast } from "react-toastify";
import  secureLocalStorage  from  "react-secure-storage";

const Categories = () => {
    
     const baseurl = "https://d3c9ouasuy8pg6.cloudf.almaxyra.ma";
    const [categories, setCategories] = useState([]);
    const [designation, setDesignation] = useState("");
    const [Uuid, setUuid] = useState("");
    const [disb, setDis] = useState(true);

    const [Verticalcenter, setVerticalcenter] = useState(-1);
    function Verticalcentermodaltoggle(index) {
      setVerticalcenter(index);
    }

    const [VaryingContentone, setVaryingContentone] = useState(false);
    const VaryingContentonetoggle = useCallback(() => {
      setVaryingContentone(!VaryingContentone);
      setModif(false);
      setDesignation("");
    }, [VaryingContentone])     
  

    const t  = secureLocalStorage.getItem('token');
    const headers = {'x-access-token': t}
    const [modif, setModif] =useState(false);

    function VaryingContentonetogglex(designation,uuid) {
      
      setModif(true);
      setVaryingContentone(!VaryingContentone);
      setDesignation(designation);
      setUuid(uuid);
    }

    const handleSubmit = () => {
      //reqres registered sample user
       const user = secureLocalStorage.getItem('uid');
       const today = new Date();
  
      const loginPayload = {
        designation:designation,
        user:user,
        date: today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
      }


    axios.post(baseurl+"/api/v1/add/categories", loginPayload)
        .then(response => {
          //get token from response
          // console.log(response);
          if(response.data.affectedRows === 1){
            toast.success("Catégorie a été bien enregistré !", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
            getData();
            VaryingContentonetoggle();
          }
       })
        .catch(err =>  toast.error("Une erreur s'est produite !, Veuillez réssayer !", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1500,
        }));
    };

    function handleChange(evt) {
      const value = evt.target.value;
      setDesignation(value); 
      if(value.trim().length===0){
        setDis(true);
      }else{
        setDis(false);
      }
    }

    function deleteitem(id){
      axios.delete(baseurl+"/api/v1/categorie/"+id,{headers:headers})
      .then((response)=>{
        getData();
        Verticalcentermodaltoggle();
        // console.log(response);
      });
    }
  
    
  function modifyitem(){
    const today = new Date();
    const loginPayload = {
      uuid: Uuid,
      designation:designation,
      date: today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
    }

      axios.patch(baseurl+"/api/v1/update/categorie", loginPayload)
      .then(response => {
        if(response.data.affectedRows === 1){
          toast.success("Affectation a été bien modifé !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          getData();
          VaryingContentonetoggle();
        }else{
            toast.error("Erreur est survenue !", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1500,
            });
            //console.log(response);
        }
     })
      .catch(err =>  {
      // console.log(err.response)
  });
  }
    function getData(){
        axios.get(baseurl+"/api/v1/categories")
        .then((response)=>{
          setCategories(response.data);
        });
    }

      useEffect(() => {
        const admin = window.location.pathname.split('/')[2];
        if(admin === 'add'){
          VaryingContentonetoggle();
        }
        getData();
          // eslint-disable-next-line react-hooks/exhaustive-deps
     },[])

  return (
    <Fragment>
    <Breadcrumb parent="Admin" title="Gestion des Catégories" />
    <Container fluid={true}>
      <Row className="p-20">
        <Button
          onClick={VaryingContentonetoggle}
          className="btn btn-air-primary"
          outline
          color="primary"
        >
          {" "}
          <i className="icon-plus"></i> {" Ajouter une Catégorie"}
        </Button>

        <Modal isOpen={VaryingContentone} toggle={VaryingContentonetoggle}>
          <ModalHeader toggle={VaryingContentonetoggle}>
            {"Ajouter une Catégorie"}
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label className="col-form-label" for="recipient-name">
                  {"Désignation"}
                </Label>
                <Input
                    type="text"
                    onChange={handleChange}
                    value={designation}
                  />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={VaryingContentonetoggle}>
              {"Fermer"}
            </Button>
            <Button color="primary" disabled={disb} onClick={modif ? modifyitem : handleSubmit}  >
              {modif? "Modifier" : "Ajouter"}
            </Button>
          </ModalFooter>
        </Modal>
      </Row>

      <Row>
        <Col sm="12">
          <Card>
            <div className="table-responsive">
              <Table className="table-border-horizontal">
                <thead>
                  <tr>
                    <th>{"#"}</th>
                    <th className="col-md-4">{"Désignation"}</th>
                    <th>{"Date"}</th>
                    <th>{"Action"}</th>
                  </tr>
                </thead>
                <tbody>
                  {categories.map((cat, index) => (
                    <tr key={index} data-index={index}>
                      <th scope="row">{cat.uuid}</th>
                      <td>{cat.designation}</td>
                      <td>{cat.created.split('T')[0]}</td>
                     <td className="text-center">
                      <button className="btn btn-edit" style={{color:'#49b85a'}} onClick={()=>VaryingContentonetogglex(cat.designation,cat.uuid)}>
                        <i className="icon-pencil-alt"></i>
                      </button>
                      <button className="btn btn-edit" style={{color:'#f93e3e'}} onClick={()=>Verticalcentermodaltoggle(index)}>
                        <i className="icon-trash"></i>
                      </button>
                      <Modal
                              isOpen={Verticalcenter === index}
                              toggle={Verticalcentermodaltoggle}
                              centered
                            >
                              <ModalHeader toggle={Verticalcentermodaltoggle}>
                                {"Confirmez-vous la suppression ?"}
                              </ModalHeader>
                              <ModalBody>
                                <p style={{textAlign:'center',marginLeft: '70px',marginRight:'70px', marginTop:'10px'}}>
                                  {
                                    "Si vous confirmez, la catégorie sera définitivement supprimée de la base"
                                  }
                                </p>
                              </ModalBody>
                              <ModalFooter>
                              <Button
                                  color="primary"
                                  onClick={(e) => {
                                    deleteitem(cat.uuid);
                                  }}
                                >
                                  {"Je confirme"}
                                </Button>

                                <Button
                                  color="secondary"
                                  onClick={Verticalcentermodaltoggle}
                                >
                                  {"Annuler"}
                                </Button>
                                
                              </ModalFooter>
                            </Modal>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Card>
        </Col>
      </Row>
     
    </Container>
  </Fragment>
  );
};

export default Categories;
