import React, { Fragment, useState, useEffect} from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button
} from "reactstrap";
import secureLocalStorage from "react-secure-storage";
import axios from "axios"

import { RotateCcw, Trash } from "react-feather";

const RestaurerProduct = () => {
  const baseurl = "https://d3c9ouasuy8pg6.cloudf.almaxyra.ma";

  const [Products, setProducts] = useState([]);
  const [VaryingContentone, setVaryingContentone] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [Verticalcenterx, setVerticalcenterx] = useState(-1);

  const toggle = (e) => {
    if(dropdownOpen === e){
      setDropdownOpen(-1);
    }else{
      setDropdownOpen(e);
    }
  }

  function Verticalcentermodaltogglexx(index) {
    setVerticalcenterx(index);
  }

  const VaryingContentonetoggle = () => {
    setVaryingContentone(!VaryingContentone);
  }
 
  const url = baseurl+"/getimage/";

  const t  = secureLocalStorage.getItem('token');
  const headers = {'x-access-token': t}

  function getData(){
    axios.get(baseurl+"/api/v1/restore/product",{headers:headers})
    .then((response)=>{
      setProducts(response.data);
    });
  }

  const [Verticalcenter, setVerticalcenter] = useState(-1);
  function Verticalcentermodaltoggle(index) {
    setVerticalcenter(index);
  }


  function deleteitem(id){
    axios.delete(baseurl+"/api/v1/restore/product/"+id,{headers:headers})
    .then((response)=>{
      Verticalcentermodaltoggle();
      getData();
      // console.log(response);
    });
  }

 function restaurer(id){
    axios.patch(baseurl+"/api/v1/update/restore/product/"+id,{headers:headers})
    .then((response)=>{
      deleteitem(id);
      Verticalcentermodaltogglexx();
      getData();
    });
  }

useEffect(() => {
    const admin = window.location.pathname.split('/')[2];
    if(admin === 'add'){
      VaryingContentonetoggle();
    }
    getData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
 },[])

  return (
    <Fragment>
    <Breadcrumb parent="Admin" title="Restaurer les Produits" />
    <Container fluid={true}>
      <Row>
        <Col sm="12">
          <Card>
            <div className="table-responsive">
              <Table className="table-border-horizontal">
                <thead>
                  <tr>
                    <th>{"#"}</th>
                    <th className="col-md-2" >{"Aperçu Image"}</th>
                    <th >{"Désignation"}</th>
                    <th className="col-md-2">{"Sous titre"}</th>
                    <th >{"Lien"}</th>
                    <th className="col-md-2">{"Date"}</th>
                    <th className="text-center">{"Action"}</th>
                  </tr>
                </thead>
                <tbody>
                  {Products.map((prod, index) => (
                    <tr key={index} data-index={index}>
                      <th scope="row" style={{verticalAlign:'middle'}}>{index+1}</th>
                      <td ><a href={"/admin/frontend/"+prod.uuid}><img className="prdimg" alt={prod.image} src={url+prod.image}/></a></td>
                      <td>{prod.designation}</td>
                      <td>{prod.subtitle}</td>
                      <td><a href={prod.getintouch} target="_blank" rel="noopener noreferrer">{prod.designation}</a></td>
                      <td>{prod.created.split("T")[0]}</td>
                      <td className="text-center">
                      <Dropdown isOpen={dropdownOpen === index} toggle={()=>toggle(index)}>
                          <DropdownToggle caret color="primary" outline={true}>Actions</DropdownToggle>
                          <DropdownMenu container="body" style={{ margin: 0 }}>
                          <DropdownItem onClick={()=>Verticalcentermodaltogglexx(index)}> <RotateCcw/> Restaurer ce Produit </DropdownItem>
                          <DropdownItem onClick={()=>Verticalcentermodaltoggle(index)}> <Trash/> Supprimer ce Produit</DropdownItem>  
                         </DropdownMenu>
                        </Dropdown>
                        <Modal
                              isOpen={Verticalcenter === index}
                              toggle={Verticalcentermodaltoggle}
                              centered
                            >
                              <ModalHeader toggle={Verticalcentermodaltoggle}>
                                {"Confirmez-vous la suppression ?"}
                              </ModalHeader>
                              <ModalBody>
                                <p style={{textAlign:'center',marginLeft: '70px',marginRight:'70px', marginTop:'10px'}}>
                                  {
                                    "Si vous confirmez, le produit sera supprimé de la base"
                                  }
                                </p>
                              </ModalBody>
                              <ModalFooter>
                              <Button
                                  color="primary"
                                  onClick={(e) => {
                                    deleteitem(prod.uuid);
                                  }}
                                >
                                  {"Je confirme"}
                                </Button>

                                <Button
                                  color="secondary"
                                  onClick={Verticalcentermodaltoggle}
                                >
                                  {"Annuler"}
                                </Button>
                                
                              </ModalFooter>
                            </Modal>
                            <Modal
                              isOpen={Verticalcenterx === index}
                              toggle={Verticalcentermodaltogglexx}
                              centered
                            >
                              <ModalHeader toggle={Verticalcentermodaltogglexx}>
                                {"Confirmez-vous la restauration ?"}
                              </ModalHeader>
                              <ModalBody>
                                <p style={{textAlign:'center',marginLeft: '70px',marginRight:'70px', marginTop:'10px'}}>
                                  {
                                    "Si vous confirmez, le produit sera restauré à la base"
                                  }
                                </p>
                              </ModalBody>
                              <ModalFooter>
                              <Button
                                  color="primary"
                                  onClick={(e) => {
                                    restaurer(prod.uuid);
                                  }}
                                >
                                  {"Je confirme"}
                                </Button>

                                <Button
                                  color="secondary"
                                  onClick={Verticalcentermodaltogglexx}
                                >
                                  {"Annuler"}
                                </Button>
                                
                              </ModalFooter>
                            </Modal>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Card>
        </Col>
      </Row>
     
    </Container>
  </Fragment>
  );
};

export default RestaurerProduct;
