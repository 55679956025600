import React, { useEffect } from 'react';
import { ChevronsUp } from 'react-feather';
import { useState } from 'react';

const Taptop = (props) => {
    const [taptopStyle,setTapTopStyle] = useState("none")
    const [placement, setPlacement] = useState("30px")
    const [side,SetSide]=useState(false);

    const data = {
        phone:'+212666154262',
        message:'Bonjour Almaxyra, J\'ai besoin de votre aide !'
    }
    const executeScroll = () => {
        window.scrollTo({top:0,left:0,behavior: 'smooth'})
    }

    const click = () => {
        const url = `https://api.whatsapp.com/send/?phone=${data.phone}&text=${data.message}`;
        window.open(url,"_blank");
    }
    const handleScroll = () => {
        if(window.scrollY > 600){
            setTapTopStyle("block")
            setPlacement("85px")
        }else{
            setTapTopStyle("none")
            setPlacement("30px")
        }
    }
    
    useEffect(() => {

        const admin = window.location.pathname.split('/')[1];
        if(admin === 'admin'){
          SetSide(true);
        }

        window.addEventListener('scroll',handleScroll)
        handleScroll();
        return () => {
            window.removeEventListener('scroll', handleScroll)
          }
    },[])

    return (
        <div>
        <button  className={side ? "float-button hide":"float-button"} onClick={click} style={{bottom : placement }} ><i className="icofont icofont-brand-whatsapp"></i></button>
        <div className="tap-top" style={{display : taptopStyle }}><ChevronsUp onClick={() => executeScroll()}/></div>
        </div>
    )
}

export default Taptop;