import React, { Fragment, useState, useEffect} from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import axios from "axios"
import Select from "react-select";

const Overview = () => {
  const baseurl = "https://d3c9ouasuy8pg6.cloudf.almaxyra.ma";

  const [Products, setProducts] = useState([]);
  const [VaryingContentone, setVaryingContentone] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [Produits, setProduits] = useState([]);
  const [produit,setProduit] = useState(" ");

  function handleChangeProduit(evt) {
    setProduit(evt.value);
  }


  const toggle = (e) => {
    if(dropdownOpen === e){
      setDropdownOpen(-1);
    }else{
      setDropdownOpen(e);
    }
  }

  const VaryingContentonetoggle = () => {
    setVaryingContentone(!VaryingContentone);
    setState({
     idproduct:" ",
     title:" ",
     description:" ",
     link:" ",
    })
    setProduit(" ");
    setModif(false);
  }
 
  const [state, setState] = React.useState({
     idproduct:" ",
     title:" ",
     description:" ",
     link:" ",
  })
  const [modif, setModif] =useState(false);
  const t  = secureLocalStorage.getItem('token');
  const headers = {'x-access-token': t}

  function getData(){
    axios.get(baseurl+"/api/v1/overview",{headers:headers})
    .then((response)=>{
      setProducts(response.data);
    });
  }

  function getProducts(){
    const t  = secureLocalStorage.getItem('token');
    const headers = {'x-access-token': t}

    axios.get(baseurl+"/api/v1/products",{headers:headers})
    .then((response)=>{
      let prds = [];
      response.data.forEach((item) => {
        prds.push({value:item.uuid,label: item.marque+' - '+item.designation});
      });
      setProduits(prds);
    });
  }

  function VaryingContentonetogglex(idproduct,title,description,link) {
    setState({
    idproduct:idproduct,
    title:title,
    description:description,
    link:link,
    })
    setProduit(idproduct);
    setModif(true);
    setVaryingContentone(!VaryingContentone);
  }

  function handleChange(evt) {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value
    });
  }
 

  const submit = (resp) => {
    //reqres registered sample user
     const user = secureLocalStorage.getItem('uid');
     const today = new Date();

    const loginPayload = {
      idproduct:produit,
      title:state.title,
      description:state.description,
      link:state.link,
      user:user,
      date: today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
    }


    axios.post(baseurl+"/api/v1/add/overview", loginPayload,{headers:headers})
      .then(response => {
        if(response.data.affectedRows === 1){
          toast.success("Aperçu a été bien enregistré !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          getData();
          VaryingContentonetoggle();
        }
     })
      .catch(err => { 
        toast.error("Aperçu existe Déjà !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1500,
          });
      });
  };


function modifyitem(){
    const today = new Date();
    const loginPayload = {
    idproduct:produit,
    title:state.title,
    description:state.description,
    link:state.link,
    date: today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
    }

      axios.patch(baseurl+"/api/v1/update/overview", loginPayload,{headers:headers})
      .then(response => {
        if(response.data.affectedRows === 1){
          toast.success("L'aperçu a été bien modifé !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          getData();
          VaryingContentonetoggle();
        }else{
            toast.error("Erreur est survenue !, Veuillez réessayer", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1500,
            });
        }
     })
      .catch(err =>  {
      // console.log(err.response)
      });
  }

useEffect(() => {
    const admin = window.location.pathname.split('/')[2];
    if(admin === 'add'){
      VaryingContentonetoggle();
    }
    getData();
    getProducts();
      // eslint-disable-next-line react-hooks/exhaustive-deps
 },[])

  return (
    <Fragment>
    <Breadcrumb parent="Admin" title="Aperçus des Produits" />
    <Container fluid={true}>
      <Row className="p-20">
        <Button
          onClick={VaryingContentonetoggle}
          className="btn btn-air-primary"
          outline
          color="primary"
        >
          {" "}
          <i className="icon-plus"></i> {" Ajouter un Aperçu"}
        </Button>

        <Modal isOpen={VaryingContentone} toggle={VaryingContentonetoggle} size='lg'>
          <ModalHeader toggle={VaryingContentonetoggle}>
            {"Ajouter un Produit"}
          </ModalHeader>
          <ModalBody>
            <Form>
             <Row>
              <div className="col-md-12">
              <FormGroup>
                <Label className="col-form-label" for="recipient-name">
                  {"Produit"}
                </Label>
              <Select options={Produits} onChange={handleChangeProduit} value = {Produits.filter(prod => prod.value === produit)}/>
              </FormGroup>
              </div>
             </Row>
             <Row>
              <div className="col-md-6">
              <FormGroup>
                <Label className="col-form-label" for="recipient-name">
                  {"Titre"}
                </Label>
                <Input
                    type="text"
                    name="title"
                    value={state.title}
                    onChange={handleChange}
                  />
              </FormGroup>
              </div>
              <div className="col-md-6">
              <FormGroup>
                <Label className="col-form-label" for="recipient-name">
                  {"Lien du Produit"}
                </Label>
                <Input
                  type="text"
                    name="link"
                    onChange={handleChange}
                    value={state.link}
                  />
              </FormGroup>
              </div>
             </Row>

             <Row>
              <div className="col-md-12">
              <FormGroup>
                <Label className="col-form-label" for="recipient-name">
                  {"Description du Produit"}
                </Label>
                <textarea className="form-control" name="description" value={state.description} onChange={handleChange} rows="5" cols="5" ></textarea>
              </FormGroup>
              </div>
             </Row>
            </Form>
          </ModalBody>
          <ModalFooter>
            
            <Button color="secondary" onClick={VaryingContentonetoggle}>
              {"Fermer"}
            </Button>
            <Button color="primary" onClick={modif ? modifyitem : submit} >
              {modif? "Modifier" : "Ajouter"}
            </Button>
          </ModalFooter>
        </Modal>
      </Row>

      <Row>
        <Col sm="12">
          <Card>
            <div className="table-responsive">
              <Table className="table-border-horizontal">
                <thead>
                  <tr>
                    <th>{"#"}</th>
                    <th className="col-md-2" >{"Produit"}</th>
                    <th >{"Titre"}</th>
                    <th className="col-md-12">{"Description"}</th>
                    <th >{"Lien"}</th>
                    <th className="col-md-2">{"Date"}</th>
                    <th className="text-center">{"Action"}</th>
                  </tr>
                </thead>
                <tbody>
                  {Products.map((prod, index) => (
                    <tr key={index} data-index={index}>
                      <th scope="row" style={{verticalAlign:'middle'}}>{index+1}</th>
                      <td >{prod.designation + ' - '+ prod.marque}</td>
                      <td>{prod.title}</td>
                      <td className="col-md-12">{prod.description}</td>
                      <td><a href={prod.link} target="_blank" rel="noopener noreferrer">{prod.title}</a></td>
                      <td>{prod.created.split("T")[0]}</td>
                      <td className="text-center">
                      <Dropdown isOpen={dropdownOpen === index} toggle={()=>toggle(index)}>
                          <DropdownToggle caret color="primary" outline={true}>Actions</DropdownToggle>
                          <DropdownMenu container="body" style={{ margin: 0 }}>
                            <DropdownItem onClick={()=>VaryingContentonetogglex(prod.idproduct,prod.title,prod.description,prod.link)}> <i className="icon-pencil-alt"></i> Modifier l'aperçu</DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Card>
        </Col>
      </Row>
     
    </Container>
  </Fragment>
  );
};

export default Overview;
