import React, { Fragment,useEffect,useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import {Download} from "react-feather";
import { CardHeader} from "reactstrap";
import { Accordion } from "react-bootstrap";
import axios from "axios"
import {ChevronRight}  from "react-feather";
import  secureLocalStorage  from  "react-secure-storage";

const FicheProduct = () => {
  const baseurl = "https://d3c9ouasuy8pg6.cloudf.almaxyra.ma";
  const url = baseurl+"/getimage/";
  
  const [activeTab, setActiveTab] = useState("1");
  const [currentActiveKey, setCurrentActiveKey] = useState(null);
  const [fix, setFix] =useState(false);
  const [product, setProduct] = useState();
  const [data, setData] = useState([]);
  const [overview, setOverview] = useState([]);
  const [specs, setSpecs] = useState("");
  const [catgs, setcatgs] = useState([]);
  const t  = secureLocalStorage.getItem('token');
  const headers = {'x-access-token': t}

  function setFixed(){
    if(window.scrollY > 640){
      setFix(true);
    }else{
      setFix(false);
    }
  }
  window.addEventListener("scroll",setFixed);


  const toggleActiveKey = (key) => {
    setCurrentActiveKey(currentActiveKey === key ? null : key);
  };

  function getDocuments(){
    const id = window.location.pathname;
    axios.get(baseurl+"/api/v1/documents/"+id.split('/')[2])
    .then((response)=>{
     setData(response.data)
     // console.log(response.data);
    })
  }

  function getCategories(){
    axios.get(baseurl+"/api/v1/categories")
    .then((response)=>{
      setcatgs(response.data);
      // console.log(response.data);
    });
  }

  function getOverview(){
    const id = window.location.pathname;
    axios.get(baseurl+"/api/v1/overview/"+id.split('/')[2],{headers:headers})
    .then((response)=>{
     setOverview(response.data[0])
    })
  }

  function getSpecs(){
    const id = window.location.pathname;
    axios.get(baseurl+"/api/v1/specs/"+id.split('/')[2],{headers:headers})
    .then((response)=>{
      if(response.data.length !== 0){
        setSpecs(response.data[0].specs);
      }
    })
  }
  useEffect(() => {
    const id = window.location.pathname;
    axios.get(baseurl+"/api/v1/product/"+id.split('/')[2])
    .then((response)=>{
      setProduct(response.data[0]);
    });
    getCategories();
    getDocuments();
    getOverview();
    getSpecs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
},[])

  return !product ? null :(
    <Fragment>
      <Col md="12" style={{ padding: "0px" }}>
        <div>
            <img src={baseurl+"/getimage/"+product.backgroundimg} className="bck" alt="background"/>
            <div style={{position:'absolute', left:0, right:0, bottom:'10%'}} className="text-center">
                <h2 className="titleprod">{product.designation}</h2>
                <p className="pprod">{product.subtitle}</p>
               <a href={product.getintouch} target="_blank" rel="noopener noreferrer"> <button className="btn btn-primary btn-lg" > En savoir plus <ChevronRight/></button></a>
            </div>
        </div>
      </Col>

      <Col md="12" style={{ padding: "0px" }} className={fix ? 'scroll-fixed':''}>
        <Card style={{ borderRadius: "0px" }}>
          <Row>
            <Col sm="7">
              <div>
                <div className="pdcustom">{product.designation}</div>
              </div>
            </Col>
            <Col sm="5">
              <Nav tabs className="border-tab">
                <NavItem>
                  <NavLink
                    className={activeTab === "1" ? "active" : ""}
                    onClick={() => setActiveTab("1")}
                  >
                    {"Aperçu"}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === "2" ? "active" : ""}
                    onClick={() => setActiveTab("2")}
                  >
                    {"Spécifications"}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === "3" ? "active" : ""}
                    onClick={() => setActiveTab("3")}
                  >
                    {"Centre de Téléchargement"}
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>
        </Card>
      </Col>

      <Col sm="12">
        <CardBody>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
            <Col xl="12" lg="12" md="12" className="text-center">
            <h1>{overview.title}</h1>
             <Row>
                <p className="col-md-3"></p>
                <p className="col-md-6">{overview.description}</p>
                <div className="col-md-12">
                  <iframe className="wdvideos" title="à propos"
                   src={overview.link} frameBorder="0" allowFullScreen>
                  </iframe>
                </div>
             </Row>
              </Col>
            </TabPane>
            <TabPane tabId="2">
            <Row className="text-center col-md-12">
            {/*<div className="col-md-2"></div>
              <div className="col-md-5"><h2 className="text-left"  style={{margin:'30px 10%'}}>CR3</h2></div>
               <p className="col-md-3 text-right"><img alt="product img" className="col-md-6" src={require('../../assets/images/CR3.png')}/></p>*/}
            </Row>
            { specs ?
            <Col xl="12" lg="12" md="12" className="text-center">
               <img src={url+specs} alt="specs" width="80%"/>
            </Col>
            /*<Col xl="12" lg="12" md="12" className="text-center">
              <h1 className="text-left" style={{margin:'30px 10%'}}>Plage de travail</h1>
              <img src={require('../../assets/images/plan1.jpeg')} alt="specs" width="80%"/>
              </Col>*/
              : " "}
            </TabPane>
            <TabPane tabId="3">
              <Row
                className="default-according style-1 faq-accordion"
                id="accordionoc" style={{justifyContent: 'center'}}
              >
                <Col xl="9" lg="6" md="7">
                  <Accordion>
                    {catgs.map((element,index)=>(
                    <Card key={index}>
                      <CardHeader className={ currentActiveKey === element.uuid ? "color-bg-dark" : "color-bg"} >
                        <h5 className="mb-0">
                          <Accordion.Toggle as={Card.Header} onClick={() => { toggleActiveKey(element.uuid);}}
                            className={currentActiveKey === element.uuid ? "btn  collapsed pl-0 btn-link-bg-dark" : "btn  collapsed pl-0 btn-link-bg" }
                            eventKey={element.uuid} aria-expanded={true}>
                            {element.designation}
                          </Accordion.Toggle>
                        </h5>
                      </CardHeader>
                      <Accordion.Collapse eventKey={element.uuid}>
                        <CardBody style={{ padding: "10px 40px" }}>
                        {data.map((ele,index)=> (
                            parseInt(ele.idcategorie,10) === element.uuid ? 
                        <div key={index}>
                                <Row style={{ padding: "20px 0px" }}>
                                    <Col xl="8">
                                    <h6>{ele.title}· {ele.created.split("T")[0]}</h6>
                                    <div><p>{ele.description}</p></div>
                                    </Col>
                                    <Col xl="4"  style={{ display: "flex", alignItems: "center",  justifyContent: "right",}} >
                                    <a href={baseurl+"/getdocs/"+ele.document} target="_blank" rel="noopener noreferrer" download><Download /></a>
                                    </Col>
                                </Row>
                                <div style={{ border: "0.1px solid #d5ddd6" }} className={index === (data.length-1) ? 'hide':''}></div>
                        </div>
                        : ""
                        ))}
                        </CardBody>
                      </Accordion.Collapse>
                    </Card>
                    ))}
                  </Accordion>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </CardBody>
      </Col>
    </Fragment>
  );
};

export default FicheProduct;
