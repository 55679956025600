import React, { Fragment,useState,useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import {Col } from 'reactstrap'
//import { DefaultLayout } from '../theme-customizer';

const Leftbar = (props) => {
  const history = useHistory();
  const [side,SetSide]=useState(false);


  //const id = window.location.pathname.split('/').pop()
  //const defaultLayout= Object.keys(DefaultLayout);
 
  //const width = useWindowSize()

  /*function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize(window.innerWidth);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }
*/

useEffect(() => {
  const admin = window.location.pathname.split('/')[1];
  if(admin === 'admin'){
    SetSide(true);
  }
}, []);


  return (
    <Fragment >
      <Col className="left-header horizontal-wrapper pl-0" >
        <ul className="horizontal-menu">
        <button className='btn-none' style={{display: 'block',textAlign:'left'}}onClick={()=>{history.push('/')}}><img className={side ? 'hide':'imgproduct'} src={require("../../assets/images/logo/almaxyra_logo.png")}  alt="" /></button>
        </ul>
      </Col>
    </Fragment>
  );
}

export default Leftbar;