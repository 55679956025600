

import FicheProduct from '../components/AlmaFront/products'
import Home from '../components/AlmaFront/home'
import Contact from '../components/AlmaFront/contacter'
import LoginWithBgImage from '../auth/login'

export const routes = [

        { path:"/products/:id", Component:FicheProduct},
        { path:"/", Component:Home},
        { path:"/contact", Component:Contact},
        { path:"/login", Component:LoginWithBgImage},

]
