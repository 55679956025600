import React, { Fragment, useState, useRef, useEffect } from "react";
import {
  Col, 
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  CardBody,
  Card,
  Button,
} from "reactstrap";
import Select from "react-select";

import {MapPin} from "react-feather";

import mapboxgl from "mapbox-gl";
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import  secureLocalStorage  from  "react-secure-storage";
import { toast } from "react-toastify";


mapboxgl.workerClass = MapboxWorker;

const Contact = () => {
  const baseurl = "https://d3c9ouasuy8pg6.cloudf.almaxyra.ma";
  const [state, setState] = React.useState({
    nom:"",
    entreprise:"",
    email:"",
    telephone:"",
    description:"",
    objet:"",
    autre:""
  })

  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(-6.8438582);
  const [lat, setLat] = useState(33.9557378);
  const [zoom, setZoom] = useState(17);
  const [hidden, setHidden] = useState(true);
  const [temp, setTemp] = useState(true);

  const options = [
    {label:'Demande d\'achat',value:'Demande d\'achat'},
    {label:'Service après-vente',value:'Service après-vente'},
    {label:'Information sur les produits',value:'Information sur les produits'},
    {label:'Autres',value:'Autres'},
  ]
  mapboxgl.accessToken =
    "pk.eyJ1IjoiMjEyYWdlbmN5IiwiYSI6ImNsZDNnM3hsZTBlenQzcm1tc3F4M2F5YzUifQ.uuAoj0Xc4TapqXLHidTYBA";

  const geojson = {
    'type': 'FeatureCollection',
    'features': [
        {
            'type': 'Feature',
            'properties': {
                'iconSize': [75, 75]
            },
        },]
};

const t  = secureLocalStorage.getItem('token');
const headers = {'x-access-token': t}

  useEffect(() => {
  
    if (map.current) return; // initialize map only once

    mapboxgl.setRTLTextPlugin(
      'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js',
      null,
      true // Lazy load the plugin
  );

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/212agency/cld5vfyp6002101p90jg0osqx",
      center: [lng, lat],
      zoom: zoom,
      projection: {
        name: 'mercator',
        center: [0, 30],
        parallels: [30, 30]
        }
    });
     // Add markers to the map.
     add_mark()
      
     const pat = window.location.pathname.split('/')[1];
  
     if(pat ==='contacter'){
      setTemp(true);
    }else{
      setTemp(false);
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[temp]);

  function add_mark(){
    setLng(-6.8417582); setLat(33.9556378); setZoom(17);

      const marker = geojson.features[0];
      // Create a DOM element for each marker.
      const el = document.createElement('div');
      const width = marker.properties.iconSize[0];
      const height = marker.properties.iconSize[1];
      el.className = 'marker';
      el.style.width = `${width}px`;
      el.style.height = `${height}px`;
      el.style.backgroundSize = '100%';
      const sp = document.createElement('a');
      sp.setAttribute('href','https://www.google.com/maps/place/Spaces+-+La+Rocade/@33.9556378,-6.8417582,15z/data=!4m6!3m5!1s0xda7139ca2320641:0x35081c12f8f0dfa6!8m2!3d33.9556378!4d-6.8417582!16s%2Fg%2F11sp65yvvh?entry=ttu');
      sp.setAttribute('target','_blank');
      el.appendChild(sp);
      new mapboxgl.Marker(el)
     .setLngLat([-6.8417582,33.9557378]) 
     .addTo(map.current);
  }

  function handleChange(evt) {
    const value = evt.target.value;
   
    setState({
      ...state,
      [evt.target.name]: value
    });
  }


  function handleChangeSelect(evt) {
    const value = evt.value;
    
    if(value ==='Autres'){
      setHidden(false);
    }else{
      setHidden(true);
    }
    setState({
      ...state,
      objet: value
    });
  }

const data = {
    phone:'+212666154262',
    message:"Bonjour Almaxyra, J'ai besoin de plus d'informations !"
}

const click = () => {
  const url = `https://api.whatsapp.com/send/?phone=${data.phone}&text=${data.message}`;
  window.open(url,"_blank");
}

  const handleSubmit = () => {
    //reqres registered sample user
     let user = secureLocalStorage.getItem('uid');
     const today = new Date();

     if(user){
      //console.log(user);
     }else{
      user="";
     }



    const loginPayload = {
      uuid:uuidv4(),
      nom: state.nom,
      entreprise: state.entreprise,
      email: state.email,
      telephone:state.telephone,
      objet:state.objet,
      message:state.description,
      lu:0,
      autre:state.autre,
      idclient:user,
      date: today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
    }

  axios.post(baseurl+"/api/v1/contact", loginPayload,{headers:headers})
      .then(response => {
        //get token from response
        if(response.data.affectedRows === 1){
          toast.success("Votre demande a été bien enregistrée !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1500,
          });
          if(user ===""){
            window.location.replace('/login');
          }else{
           window.location.reload();
          }
        }
     })
      .catch(err =>  toast.error("Une erreur s'est produite !, Veuillez réssayer !", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1500,
      }));
  };

  return (
    <Fragment>
      <Col xl="12" lg="6" md="7" style={{ padding: "20px 60px" }}>
        <div className="faq-title">
          <h1 className="titleprod">{"Contactez-nous"}</h1>
          <p>{"Vous pouvez nous contacter!"}</p>
        </div>
      </Col>

      <Col style={{padding:0, margin:0}}>
          <div ref={mapContainer}  style={{height:'400px'}} />
      </Col>
      <Col>
          <div className="box-contact blanc">
            <h1>Almaxyra & Company</h1>
            <br/>
             <p>
              <MapPin/>
              <span className="fz-18"> Centre Spaces la Rocade, Bir Kacem-Souissi 10100 Rabat Maroc</span>
             </p>
          </div>
      </Col>
      <Col xl="12" className="paddcontact">
      <Card>
        <CardBody>
        <h1>{"Ecrivez-Nous"}</h1>
        <Form>
         <Row>
                <FormGroup className="col-md-6">
                  <Label className="col-form-label" for="recipient-name">
                    {"Nom"}
                  </Label>
                  <Input
                    type="text"
                    name="nom"
                    onChange={handleChange}
                  />
                  </FormGroup>
                <FormGroup className="col-md-6">
                  <Label className="col-form-label" for="recipient-name">
                    {"Entreprise"}
                  </Label>
                  <Input
                    type="text"
                    name="entreprise"
                    onChange={handleChange}
                  />
                  </FormGroup>
            </Row>
            <Row>
                <FormGroup className="col-md-6">
                  <Label className="col-form-label" for="recipient-name">
                    {"Email"}
                  </Label>
                  <Input
                    type="email"
                    name="email"
                    onChange={handleChange}
                  />
                  </FormGroup>
                <FormGroup className="col-md-6">
                  <Label className="col-form-label" for="recipient-name">
                    {"Téléphone"}
                  </Label>
                  <Input
                    type="tel"
                    name="telephone"
                    onChange={handleChange}
                  />
                  </FormGroup>
            </Row>
            <Row>
                <FormGroup  className="col-md-12">
                  <Label className="col-form-label" for="recipient-name">
                    {"Objet"}
                  </Label>
                  <Select options={options}  onChange={handleChangeSelect} name="object"/>
                  </FormGroup>
            </Row>
            <Row hidden={hidden}>
                <FormGroup className="col-md-12">
                  <Label className="col-form-label" for="recipient-name">
                    {"Autre"}
                  </Label>
                  <Input
                    type="text"
                    name="autre"
                    onChange={handleChange}
                  />
                  </FormGroup>
            </Row>
            <Row>
                <FormGroup  className="col-md-12">
                  <Label className="col-form-label" for="recipient-name">
                    {"Message"}
                  </Label>
                  <Input  type="textarea"  name="description"  onChange={handleChange} rows="5" />
                  </FormGroup>
            </Row>
            <Row><Button className="btn ml-3" color="primary" onClick={handleSubmit}>ENVOYER</Button></Row>
           </Form>
        </CardBody>
        <button  className={temp ? "float-button" : "float-button hide"}  onClick={click} style={{bottom:"30px"}}><i className="icofont icofont-brand-whatsapp"></i></button>
      </Card>
      </Col>
    </Fragment>
  );
};

export default Contact;
