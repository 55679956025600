import React,{useState} from 'react';
import {Container,Row,Col,Form,FormGroup,Input,Label,Button} from 'reactstrap'
import {Eye,EyeOff} from 'react-feather';
import axios from "axios"
import  secureLocalStorage  from  "react-secure-storage";
import { toast } from "react-toastify";


const LoginWithBgImage = (props)  => {

    const baseurl = "https://d3c9ouasuy8pg6.cloudf.almaxyra.ma";
    const [user, setUser] = useState("");
    const [password, setPassword] = useState("");
    const [togglePassword,setTogglePassword] = useState(false)

    const HideShowPassword  = (tPassword) => {
      setTogglePassword(!tPassword)
    }


  const data = {
      phone:'+212666154262',
      message:"Bonjour Almaxyra, J'ai besoin de plus d'informations !"
  }
  
  const click = () => {
    const url = `https://api.whatsapp.com/send/?phone=${data.phone}&text=${data.message}`;
    window.open(url,"_blank");
  }


  const handleSubmit = () => {
    //reqres registered sample user
    const loginPayload = {
      user: user,
      pass: password
    }
    

    axios.post(baseurl+"/api/v1/login", loginPayload)
      .then(response => {
        //get token from response
        toast.success("Bienvenue !", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        if(response.data.auth === true){
          const token  =  response.data.token;
          const username = response.data.result.name;
          const uuid = response.data.result.uuid;
          secureLocalStorage.setItem('uid', uuid);
          secureLocalStorage.setItem('name', username);
          secureLocalStorage.setItem('token', token);

          toast.success("Bienvenue !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });

          if(response.data.t === 0){
            secureLocalStorage.setItem('trole',0);
            window.location.href = `${process.env.PUBLIC_URL}/`;
          }else{
            secureLocalStorage.setItem('trole',1);
            window.location.href = `${process.env.PUBLIC_URL}/admin/dashboard`;
          }
        }else{
          //console.log(response);
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1500,
          });
        }
     })
      .catch(err => {
         //console.log(err)
        });
  };

    return (
   <Container fluid={true}>
      <Row className='leftmadrg'>
        <Col xl="6" className='bgvideo' style={{paddingLeft: 0}} >
        <video src={require('../assets/video/login.mp4')}  width="100%" height="100%"  autoPlay muted loop  playsInline>
         </video>
           {/* <iframe title="almaxyra" src={require('../assets/video/robot-hand.mp4')} style={{marginTop:"0px"}} width="100%" height="100%" frameBorder="0" allow="autoplay;picture-in-picture"></iframe>
            <div className="titre">
              <h2>PLATEFORME RESSOURCES ÉDUCATIVES ALMAXYRA COMPANY</h2>
               </div>*/}
          </Col>
  
          <Col xl="6" className='1'>
          <Row>
          <div className="col-md-2">
          </div>
          <div className="col-md-8">
              <div>
              <Row>
                <Col xl="12" className="p-0">
                      <div className='text-center pad'>
                          <span className="text-center">
                          <img className="img-fluid for-light widlogo" src={require("../assets/images/logo/almaxyra_logo.png")} alt="looginpage"/>
                          </span>
                        </div>
                    </Col>
                </Row>
                <div  style={{width:"100%", marginTop:"20px"}}>
                <h3 className='h3pad'>Bienvenue sur notre plateforme ressources éducatives</h3>
                        <Form className="theme-form">
                          <div className='text-center'>
                              <h2 style={{fontFamily: 'conthrax',color: '#49b859'}}> UTILISATEUR EN LIGNE</h2>
                          </div>

                          <FormGroup>
                            <Label className="col-form-label">{"Adresse Email*"}</Label>
                            <Input className="form-control" type="email" required={true} placeholder="test@email.com" onChange={e => setUser(e.target.value)} defaultValue={user} />
                          </FormGroup>
                          <FormGroup>
                            <Label className="col-form-label">{"Mot de passe*"}</Label>
                            <Input className="form-control" type={togglePassword ?  "text" : "password" } name="login[password]"  onChange={e => setPassword(e.target.value)} defaultValue={password} required={true} placeholder="*********"/>
                            <div className="show-hide" onClick={() => HideShowPassword(togglePassword)}>
                              {togglePassword ? <EyeOff color='#6d757d' size={15}/> :<Eye color='#6d757d'size={15} />}</div>
                          </FormGroup>
                          <FormGroup className="mb-0">
                            <div className="checkbox ml-3">
                              <Input id="checkbox1" type="checkbox"/>
                              <Label className="text-muted" for="checkbox1">{"Rester connecté"}</Label>
                            </div>
                              <Button color="primary" className="btn-block" onClick={handleSubmit} >{"Connexion"}</Button>
                              <hr/>
                              <p className='text-center'>Pas de compte ? <a href='/contacter'>Contactez-nous</a></p>
                          </FormGroup>
                        </Form>
                </div>
                <button  className="float-button" onClick={click}><i className="icofont icofont-brand-whatsapp"></i></button>
              </div>
            </div>
            </Row>
          </Col>
        </Row>
        </Container>
    );
}

export default LoginWithBgImage;

