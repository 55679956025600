import React, { Fragment, useState, useEffect} from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import axios from "axios"
import Select from "react-select";
//import Dropzone from 'react-dropzone-uploader'
import Dropzone from 'react-dropzone'

const Specs = () => {
  const baseurl = "https://d3c9ouasuy8pg6.cloudf.almaxyra.ma";

  const [Products, setProducts] = useState([]);
  const [VaryingContentone, setVaryingContentone] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [Produits, setProduits] = useState([]);
  const [produit,setProduit] = useState(" ");
  const [disb, setDisb] = useState(true);
  const [uploadedtime, setuploadedtime] = useState(0)
  const [files, setFiles] = useState([]);


  const [Verticalcenter, setVerticalcenter] = useState(-1);
  function Verticalcentermodaltoggle(index) {
    setVerticalcenter(index);
  }

  const url = baseurl+"/getimage/";

  function handleChangeProduit(evt) {
    setProduit(evt.value);
  }


  const toggle = (e) => {
    if(dropdownOpen === e){
      setDropdownOpen(-1);
    }else{
      setDropdownOpen(e);
    }
  }

  const VaryingContentonetoggle = () => {
    setVaryingContentone(!VaryingContentone);

    setProduit(" ");
  }
 

  const t  = secureLocalStorage.getItem('token');
  const headers = {'x-access-token': t}

  

  function getData(){
    axios.get(baseurl+"/api/v1/specs",{headers:headers})
    .then((response)=>{
      setProducts(response.data);
    });
  }

  function getProducts(){
    const t  = secureLocalStorage.getItem('token');
    const headers = {'x-access-token': t}

    axios.get(baseurl+"/api/v1/products",{headers:headers})
    .then((response)=>{
      let prds = [];
      response.data.forEach((item) => {
        prds.push({value:item.uuid,label: item.marque+' - '+item.designation});
      });
      setProduits(prds);
    });
  }

 
  const handle = () =>{
    handleSubmit();
  }

  const handleSubmit = async () => {
    const body = new FormData()

      body.append('img', files[0],files[0].name);

     try {
       const response = await axios.post(baseurl+"/api/v1/upload/images", body,{
        onUploadProgress : (data) =>{
          setuploadedtime(Math.round((data.loaded / data.total)*100));
        }
      });
       submit(response.data);
     } catch (error) {
       //console.log(error);
       return "API error.";
     }
   }


  const submit = (resp) => {
    //reqres registered sample user
     const user = secureLocalStorage.getItem('uid');
     const today = new Date();

    const loginPayload = {
      idproduct:produit,
      specs:resp,
      user:user,
      date: today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
    }


    axios.post(baseurl+"/api/v1/add/specs", loginPayload,{headers:headers})
      .then(response => {
        if(response.data.affectedRows === 1){
          toast.success("Spécification a été bien enregistré !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          getData();
          VaryingContentonetoggle();
        }
     })
      .catch(err => { 
        toast.error("Spécification existe Déjà !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1500,
          });
      });
  };

  function deleteitem(id){
    axios.delete(baseurl+"/api/v1/spec/"+id,{headers:headers})
    .then((response)=>{
      Verticalcentermodaltoggle();
      getData();
    })
    .catch(err => { 
        toast.error("Spécification n'est pas supprimé !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1500,
          });
      });
  }

  const uploadFiles = (acceptedFiles) =>{
    setFiles(acceptedFiles);
    setDisb(false);
}

const acceptedFilesItems = files.map(file => (
<li key={file.path}>
  {file.path}
</li>
));


useEffect(() => {
    const admin = window.location.pathname.split('/')[2];
    if(admin === 'add'){
      VaryingContentonetoggle();
    }
    getData();
    getProducts();
      // eslint-disable-next-line react-hooks/exhaustive-deps
 },[])

  return (
    <Fragment>
    <Breadcrumb parent="Admin" title="Spécifications des Produits" />
    <Container fluid={true}>
      <Row className="p-20">
        <Button
          onClick={VaryingContentonetoggle}
          className="btn btn-air-primary"
          outline
          color="primary"
        >
          {" "}
          <i className="icon-plus"></i> {" Ajouter une Spécification"}
        </Button>

        <Modal isOpen={VaryingContentone} toggle={VaryingContentonetoggle} size='lg'>
          <ModalHeader toggle={VaryingContentonetoggle}>
            {"Ajouter un Produit"}
          </ModalHeader>
          <ModalBody>
            <Form>
             <Row>
              <div className="col-md-12">
              <FormGroup>
                <Label className="col-form-label" for="recipient-name">
                  {"Produit"}
                </Label>
              <Select options={Produits} onChange={handleChangeProduit} value = {Produits.filter(prod => prod.value === produit)}/>
              </FormGroup>
              </div>
             </Row>
             <Row>
              <div className="col-md-12">
              <FormGroup>
                <Label className="col-form-label" for="recipient-name">
                  {"Spécification du Produit"}
                </Label>
                {/*<Dropzone
                    getUploadParams={getUploadParamsback}
                    onChangeStatus={handleChangeStatusback}
                    accept="image/*"
                    maxFiles={1}
                    multiple={false}
                    inputContent="Déposer l'image des spécifications"
                    styles={{
                        dropzone: { width: '100%', height: '180px' },
                        dropzoneActive: { borderColor: 'green' },
                        }}
                      />*/}

                     <Dropzone maxFiles={1}  onDrop={(acceptedFiles) => uploadFiles(acceptedFiles)} >
                          {({getRootProps, getInputProps}) => (
                            <section className="dragnddrop">
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <p>Déposer l'image des spécifications  (Un seul fichier)</p> 
                                {acceptedFilesItems}
                              </div>
                            </section>
                          )}
                      </Dropzone>
                      
                 <div className="progress mt-2">
                    <div className="progress-bar" role="progressbar"  aria-valuemax="100"
                    aria-valuenow={uploadedtime} aria-valuemin="0" style={{width: `${uploadedtime}%`}}>
                        {`${uploadedtime}%`}
                    </div>
                </div>
              </FormGroup>
              </div>
             </Row>
            </Form>
          </ModalBody>
          <ModalFooter>
            
            <Button color="secondary" onClick={VaryingContentonetoggle}>
              {"Fermer"}
            </Button>
            <Button color="primary" onClick={handle}  disabled={disb}>
              {"Ajouter"}
            </Button>
          </ModalFooter>
        </Modal>
      </Row>

      <Row>
        <Col sm="12">
          <Card>
            <div className="table-responsive">
              <Table className="table-border-horizontal">
                <thead>
                  <tr>
                    <th>{"#"}</th>
                    <th className="col-md-2" >{"Produit"}</th>
                    <th className="col-md-12">{"Spécification"}</th>
                    <th className="col-md-2">{"Date"}</th>
                    <th className="text-center">{"Action"}</th>
                  </tr>
                </thead>
                <tbody>
                  {Products.map((prod, index) => (
                    <tr key={index} data-index={index}>
                      <th scope="row" style={{verticalAlign:'middle'}}>{index+1}</th>
                      <td >{prod.designation + ' - '+ prod.marque}</td>
                      <td className="col-md-12"><a target="_blank" rel="noopener noreferrer" href={url+prod.specs}>Spécification</a></td>
                      <td>{prod.created.split("T")[0]}</td>
                      <td className="text-center">
                      <Dropdown isOpen={dropdownOpen === index} toggle={()=>toggle(index)}>
                          <DropdownToggle caret color="primary" outline={true}>Actions</DropdownToggle>
                          <DropdownMenu container="body" style={{ margin: 0 }}>
                          <DropdownItem onClick={()=>Verticalcentermodaltoggle(index)}><i className="icon-trash"></i> Supprimer les spécifications</DropdownItem>                         
                          </DropdownMenu>
                        </Dropdown>
                      <Modal
                              isOpen={Verticalcenter === index}
                              toggle={Verticalcentermodaltoggle}
                              centered
                            >
                              <ModalHeader toggle={Verticalcentermodaltoggle}>
                                {"Confirmez-vous la suppression ?"}
                              </ModalHeader>
                              <ModalBody>
                                <p style={{textAlign:'center',marginLeft: '70px',marginRight:'70px', marginTop:'10px'}}>
                                  {
                                    "Si vous confirmez, les spécifications seront définitivement supprimé de la base"
                                  }
                                </p>
                              </ModalBody>
                              <ModalFooter>
                              <Button
                                  color="primary"
                                  onClick={() => {
                                    deleteitem(prod.idproduct)
                                  }}
                                >{"Je confirme"}
                                </Button>

                                <Button
                                  color="secondary"
                                  onClick={Verticalcentermodaltoggle}
                                >
                                  {"Annuler"}
                                </Button>
                                
                              </ModalFooter>
                            </Modal>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Card>
        </Col>
      </Row>
     
    </Container>
  </Fragment>
  );
};

export default Specs;
