import React, { Fragment, useState, useEffect} from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import axios from "axios"
import { v4 as uuidv4 } from 'uuid';
//import Dropzone from 'react-dropzone-uploader'
import Dropzone from 'react-dropzone'


const Products = () => {
  const baseurl = "https://d3c9ouasuy8pg6.cloudf.almaxyra.ma";

  const [Products, setProducts] = useState([]);
  const [VaryingContentone, setVaryingContentone] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [uploadedtime, setuploadedtime] = useState(0)
  const [filesImage, setFilesImage] = useState([]);
  const [filesBack, setFilesBack] = useState([]);

  const [dis, setDis] = useState(true);


  const toggle = (e) => {
    if(dropdownOpen === e){
      setDropdownOpen(-1);
    }else{
      setDropdownOpen(e);
    }
  }

  const VaryingContentonetoggle = () => {
    setVaryingContentone(!VaryingContentone);
    setState({
      uuid:"",
      designation:"",
      subtitle:"",
      marque:"",
      description:"",
      getintouch:"",
      specifications:"",
    })
    setModif(false);
  }
 
  const url = baseurl+"/getimage/";
  const [state, setState] = React.useState({
    uuid:"",
    designation:"",
    subtitle:"",
    marque:"",
    description:"",
    image:"",
    backgroundimg:"",
    getintouch:"",
    specifications:"",
  })
 
  const [modif, setModif] =useState(false);
  const t  = secureLocalStorage.getItem('token');
  const headers = {'x-access-token': t}

  function getData(){
    axios.get(baseurl+"/api/v1/products",{headers:headers})
    .then((response)=>{
      setProducts(response.data);
    });
  }

  const [Verticalcenter, setVerticalcenter] = useState(-1);
  function Verticalcentermodaltoggle(index) {
    setVerticalcenter(index);
  }

  function VaryingContentonetogglex(designation,subtitle,description,marque,getintouch,uuid) {
    setState({
      uuid:uuid,
      designation:designation,
      subtitle:subtitle,
      marque:marque,
      description:description,
      getintouch:getintouch,
      specifications:"",
    })
    setModif(true);
    setVaryingContentone(!VaryingContentone);
   // setDesignation(designation);
   // setUuid(uuid);
  }

  function handleChange(evt) {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value
    });
  }

  /*const handleChangeStatus = ({ meta, file }, status) => { 

    if(status==='done'){
      setImage(file);
    }

  }

  const getUploadParams = ({ meta }) => { 
    return { url: 'https://httpbin.org/post'} 
  }

  const handleChangeStatusback = ({ meta, file }, status) => { 

    if(status==='done'){
      setBackimage(file);
    }

  }

  const getUploadParamsback = ({ meta }) => { 
    return { url: 'https://httpbin.org/post'} 
  }
*/
  const uploadFiles = (acceptedFiles,type) =>{
    if(type === 1){
      setFilesImage(acceptedFiles);
    }else{
      setFilesBack(acceptedFiles);
    }


    if(filesImage.length !== 0 || filesBack.length !== 0 ){
      setDis(false);
    }
}

const acceptedFilesItemsBack = filesBack.map(file => (
  <li key={file.path}>
    {file.path}
  </li>
));

const acceptedFilesItems = filesImage.map(file => (
  <li key={file.path}>
    {file.path}
  </li>
));


  const handleSubmit = async () => {
    const body = new FormData()

      
      body.append('img', filesImage[0],filesImage[0].name);
      body.append('img', filesBack[0],filesBack[0].name);

     try {
       const response = await axios.post(baseurl+"/api/v1/upload/images", body,{
        onUploadProgress : (data) =>{
          setuploadedtime(Math.round((data.loaded / data.total)*100));
        }
      });
       submit(response.data);
     } catch (error) {
       //console.log(error);
       return "API error.";
     }
   }
 

  const submit = (resp) => {
    //reqres registered sample user
     const user = secureLocalStorage.getItem('uid');
     const today = new Date();

    const loginPayload = {
      uuid:uuidv4(),
      designation:state.designation,
      subtitle:state.subtitle,
      marque:state.marque,
      descriptions:state.description,
      image:resp[0],
      backgroundimg:resp[1],
      getintouch:state.getintouch,
      user:user,
      date: today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
    }


    axios.post(baseurl+"/api/v1/add/products", loginPayload,{headers:headers})
      .then(response => {
        //get token from response
        // console.log(response);
        if(response.data.affectedRows === 1){
          toast.success("Produit a été bien enregistré !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          getData();
          VaryingContentonetoggle();
        }else{
            toast.error("Produit existe Déjà !", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1500,
            });
        }
     })
      .catch(err => { 
        // console.log(err)
      });
  };

  function deleteitem(id){
    axios.delete(baseurl+"/api/v1/product/"+id,{headers:headers})
    .then((response)=>{
      Verticalcentermodaltoggle();
      getData();
      // console.log(response);
    });
  }

function modifyitem(){
    const today = new Date();
    const loginPayload = {
    uuid: state.uuid,
    designation:state.designation,
    subtitle:state.subtitle,
    marque:state.marque,
    description:state.description,
    getintouch:state.getintouch,
    date: today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
    }

      axios.patch(baseurl+"/api/v1/update/product", loginPayload,{headers:headers})
      .then(response => {
        if(response.data.affectedRows === 1){
          toast.success("Produit a été bien modifé !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          getData();
          VaryingContentonetoggle();
        }else{
            toast.error("Erreur est survenue !", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1500,
            });
            //console.log(response);
        }
     })
      .catch(err =>  {
      // console.log(err.response)
      });
  }

useEffect(() => {
    const admin = window.location.pathname.split('/')[2];
    if(admin === 'add'){
      VaryingContentonetoggle();
    }
    getData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
 },[])

  return (
    <Fragment>
    <Breadcrumb parent="Admin" title="Gestion des Produits" />
    <Container fluid={true}>
      <Row className="p-20">
        <Button
          onClick={VaryingContentonetoggle}
          className="btn btn-air-primary"
          outline
          color="primary"
        >
          {" "}
          <i className="icon-plus"></i> {" Ajouter un Produit"}
        </Button>

        <Modal isOpen={VaryingContentone} toggle={VaryingContentonetoggle} size='lg'>
          <ModalHeader toggle={VaryingContentonetoggle}>
            {"Ajouter un Produit"}
          </ModalHeader>
          <ModalBody>
            <Form>
             <Row>
              <div className="col-md-6">
              <FormGroup>
                <Label className="col-form-label" for="recipient-name">
                  {"Désignation du Produit"}
                </Label>
                <Input
                    type="text"
                    name="designation"
                    onChange={handleChange}
                    value={state.designation}
                  />
              </FormGroup>
              </div>
              <div className="col-md-6">
              <FormGroup>
                <Label className="col-form-label" for="recipient-name">
                  {"Slogan du Produit"}
                </Label>
                <Input
                    type="text"
                    name="subtitle"
                    value={state.subtitle}
                    onChange={handleChange}
                  />
              </FormGroup>
              </div>
             </Row>
             <Row>
              <div className="col-md-6">
              <FormGroup>
                <Label className="col-form-label" for="recipient-name">
                  {"Marque du Produit"}
                </Label>
                <Input
                    type="marque"
                    name="marque"
                    value={state.marque}
                    onChange={handleChange}
                  />
              </FormGroup>
              </div>
              <div className="col-md-6">
              <FormGroup>
                <Label className="col-form-label" for="recipient-name">
                  {"Lien du Partenaire"}
                </Label>
                <Input
                  type="text"
                    name="getintouch"
                    onChange={handleChange}
                    value={state.getintouch}
                  />
              </FormGroup>
              </div>
             </Row>

             <Row>
              <div className="col-md-12">
              <FormGroup>
                <Label className="col-form-label" for="recipient-name">
                  {"Description du Produit"}
                </Label>
                <textarea className="form-control" name="description" value={state.description} onChange={handleChange} rows="5" cols="5" ></textarea>
              </FormGroup>
              </div>
             </Row>
             {modif? <p></p> :
             <Row>
              <div className="col-md-6">
              <FormGroup>
                <Label className="col-form-label" for="recipient-name">
                  {"Image du Produit"}
                </Label>
                {/*<Dropzone
                                    getUploadParams={getUploadParams}
                                    onChangeStatus={handleChangeStatus}
                                    //onSubmit={handleSubmit}
                                    accept="image/*"
                                    maxFiles={1}
                                    multiple={false}
                                    //canCancel={true}
                                    inputContent="Déposer une image du background image"
                                    styles={{
                                        dropzone: { width: '100%', height: '180px' },
                                        dropzoneActive: { borderColor: 'green' },
                                    }}
                                  />*/}
                     <Dropzone maxFiles={1}  onDrop={(acceptedFiles) => uploadFiles(acceptedFiles,1)} >
                          {({getRootProps, getInputProps}) => (
                            <section className="dragnddrop">
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <p>Déposer de l'image du produit (Un seul fichier)</p> 
                                {acceptedFilesItems}
                              </div>
                            </section>
                          )}
                      </Dropzone>
              </FormGroup>
              </div>
              <div className="col-md-6">
              <FormGroup>
                <Label className="col-form-label" for="recipient-name">
                  {"Image du Background"}
                </Label>
                   {/* <Dropzone
                                    getUploadParams={getUploadParamsback}
                                    onChangeStatus={handleChangeStatusback}
                                    //onSubmit={handleSubmit}
                                    accept="image/*"
                                    maxFiles={1}
                                    multiple={false}
                                    //canCancel={true}
                                    inputContent="Déposer une image du background backgroundimg"
                                    styles={{
                                        dropzone: { width: '100%', height: '180px' },
                                        dropzoneActive: { borderColor: 'green' },
                                    }}
                                />*/}
                   <Dropzone maxFiles={1}  onDrop={(acceptedFiles) => uploadFiles(acceptedFiles,2)} >
                          {({getRootProps, getInputProps}) => (
                            <section className="dragnddrop">
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <p>Déposer l'image du Background (Un seul fichier)</p> 
                                {acceptedFilesItemsBack}
                              </div>
                            </section>
                          )}
                      </Dropzone>
              </FormGroup>
              </div>
              <div className="col-md-12">
                <div className="progress mt-2">
                    <div className="progress-bar" role="progressbar"  aria-valuemax="100"
                    aria-valuenow={uploadedtime} aria-valuemin="0" style={{width: `${uploadedtime}%`}}>
                        {`${uploadedtime}%`}
                    </div>
                </div>
              </div>
             </Row>
             }
            </Form>
          </ModalBody>
          <ModalFooter>
            
            <Button color="secondary" onClick={VaryingContentonetoggle}>
              {"Fermer"}
            </Button>
            <Button color="primary" onClick={modif ? modifyitem : handleSubmit} disabled={dis} >
              {modif? "Modifier" : "Ajouter"}
            </Button>
          </ModalFooter>
        </Modal>
      </Row>

      <Row>
        <Col sm="12">
          <Card>
            <div className="table-responsive">
              <Table className="table-border-horizontal">
                <thead>
                  <tr>
                    <th>{"#"}</th>
                    <th className="col-md-2" >{"Aperçu Image"}</th>
                    <th >{"Désignation"}</th>
                    <th className="col-md-2">{"Sous titre"}</th>
                    <th >{"Lien"}</th>
                    <th className="col-md-2">{"Date"}</th>
                    <th className="text-center">{"Action"}</th>
                  </tr>
                </thead>
                <tbody>
                  {Products.map((prod, index) => (
                    <tr key={index} data-index={index}>
                      <th scope="row" style={{verticalAlign:'middle'}}>{index+1}</th>
                      <td ><div>
                       <a href={"/admin/frontend/"+prod.uuid}><img className="prdimg" alt={prod.image} src={url+prod.image}/></a></div></td>
                      <td>{prod.designation}</td>
                      <td>{prod.subtitle}</td>
                      <td><a href={prod.getintouch} target="_blank" rel="noopener noreferrer">{prod.designation}</a></td>
                      <td>{prod.created.split("T")[0]}</td>
                      <td className="text-center">
                      <Dropdown isOpen={dropdownOpen === index} toggle={()=>toggle(index)}>
                          <DropdownToggle caret color="primary" outline={true}>Actions</DropdownToggle>
                          <DropdownMenu container="body" style={{ margin: 0 }}>
                            <DropdownItem onClick={()=>VaryingContentonetogglex(prod.designation,prod.subtitle,prod.description,prod.marque,prod.getintouch,prod.uuid,prod.image)}> <i className="icon-pencil-alt"></i> Modifier le produit</DropdownItem>                 
                            <DropdownItem onClick={()=>Verticalcentermodaltoggle(index)}><i className="icon-trash"></i> Supprimer le produit</DropdownItem>                         
                          </DropdownMenu>
                        </Dropdown>
                      <button className="btn btn-edit hide" style={{color:'#f93e3e'}} >
                        <i className="icon-trash"></i>
                      </button>
                      <Modal
                              isOpen={Verticalcenter === index}
                              toggle={Verticalcentermodaltoggle}
                              centered
                            >
                              <ModalHeader toggle={Verticalcentermodaltoggle}>
                                {"Confirmez-vous la suppression ?"}
                              </ModalHeader>
                              <ModalBody>
                                <p style={{textAlign:'center',marginLeft: '70px',marginRight:'70px', marginTop:'10px'}}>
                                  {
                                    "Si vous confirmez, le produit sera définitivement supprimé de la base"
                                  }
                                </p>
                              </ModalBody>
                              <ModalFooter>
                              <Button
                                  color="primary"
                                  onClick={(e) => {
                                    deleteitem(prod.uuid);
                                  }}
                                >
                                  {"Je confirme"}
                                </Button>

                                <Button
                                  color="secondary"
                                  onClick={Verticalcentermodaltoggle}
                                >
                                  {"Annuler"}
                                </Button>
                                
                              </ModalFooter>
                            </Modal>

                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Card>
        </Col>
      </Row>
     
    </Container>
  </Fragment>
  );
};

export default Products;
