import React, { Fragment, useState, useEffect} from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
    Container,
    Row,
    Col,
    Card,
    Table,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label,
    Input,
    Media
} from "reactstrap";
import { toast } from "react-toastify";
import axios from "axios"
import { v4 as uuidv4 } from 'uuid';
import  secureLocalStorage  from  "react-secure-storage";

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';


const Clients = () => {
  const baseurl = "https://d3c9ouasuy8pg6.cloudf.almaxyra.ma";
    const [VaryingContentone, setVaryingContentone] = useState(false);
    const [state, setState] = React.useState({
      id:"",
      email:"",
      pwd:"",
      prenom: "",
      fonction: "",
      tel: "",
      active:1,
      name:"",
      adresse:"",
      pays:"",
      ville:"",
      ice:"",
      activite:"",
      de : true,
    });
    const [modif, setModif] =useState(false);
    const [Clients, setClients] = useState([]);
    const t  = secureLocalStorage.getItem('token');
    const headers = {'x-access-token': t}

    function VaryingContentonetogglex(namev,emailv,pwdv,prenomv,fonctionv,telv,adressev, paysv, villev,icev,activitev,activev,id) {
      setState({
        id:id,
        email:emailv,
        name: namev,
        pwd: pwdv,
        prenom: prenomv,
        fonction: fonctionv,
        tel: telv,
        adresse:adressev,
        pays:paysv,
        ville:villev,
        ice:icev,
        activite:activitev,
        de: true,
        active:activev
      });
      setModif(true);
      setVaryingContentone(!VaryingContentone);
    }
  
  function VaryingContentonetoggle () {
    setState({
      email:"",
      name: "",
      prenom: "",
      fonction: "",
      tel: "",
      adresse:"",
      pays:"",
      ville:"",
      ice:"",
      activite:"",
      de:false,
      active:1
    });
    setModif(false);
      setVaryingContentone(!VaryingContentone);
    }
  const [Verticalcenter, setVerticalcenter] = useState(-1);
  function Verticalcentermodaltoggle(index) {
    setVerticalcenter(index);
  }

    function handleChange(evt) {
      const value = evt.target.value;
      setState({
        ...state,
        [evt.target.name]: value
      });
    }

  
const exportToExcel = (jsonData, excludedColumns) => {
        // Filter the keys of the first row to exclude the specified columns
        const filteredKeys = Object.keys(jsonData[0]).filter(key => !excludedColumns.includes(key));

        // Create a new worksheet with filtered column keys
        const worksheet = XLSX.utils.json_to_sheet(jsonData, { header: filteredKeys });
   
     // const worksheet = XLSX.utils.json_to_sheet(jsonData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Almaxyra Clients');
  
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(data, 'clients.xlsx');
    };
const  handelExcel = (data)=>{
  const jsonData = data;
  const excludedColumns = ['uuid', 'user'];

  exportToExcel(jsonData,excludedColumns);
}

  const handleSubmit = () => {
    //reqres registered sample user
     const user = secureLocalStorage.getItem('uid');
     const today = new Date();

    const loginPayload = {
      uuid:uuidv4(),
      name:state.name,
      email:state.email,
      pwd:state.pwd,
      prenom: state.prenom,
      fonction: state.fonction,
      tel: state.tel,
      type:'client',
      adresse:state.adresse,
      pays:state.pays,
      ville:state.ville,
      ice:state.ice,
      activite:state.activite,
      active:state.active,
      user:user,
      date: today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
    }

    axios.post(baseurl+"/api/v1/registration", loginPayload)
      .then(response => {
        //get token from response
        //console.log(response);
        if(response.data.affectedRows === 1){
          toast.success("Client a été bien enregistré !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          getData();
          VaryingContentonetoggle();
        }else{
            toast.error("Utilisateur existe Déjà !", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1500,
            });
        }
     })
      .catch(err =>  {
      // console.log(err.response)
      });
  };

  function deleteitem(id,e){
    axios.delete(baseurl+"/api/v1/client/"+id,{headers:headers})
    .then((response)=>{
      getData();
      Verticalcentermodaltoggle(e);
    });
  }

  function modifyitem(){
    const today = new Date();
    const loginPayload = {
      id: state.id,
      name:state.name,
      prenom: state.prenom,
      fonction: state.fonction,
      tel: state.tel,
      email:state.email,
      adresse:state.adresse,
      pays:state.pays,
      ville:state.ville,
      ice:state.ice,
      activite:state.activite,
      active:state.active,
      date: today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
    }

      axios.patch(baseurl+"/api/v1/update/client", loginPayload)
      .then(response => {
        if(response.data.affectedRows === 1){
          toast.success("Client a été bien modifé !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          getData();
          VaryingContentonetoggle();
        }else{
            toast.error("Erreur est survenue !", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1500,
            });
        }
     })
      .catch(err =>  {
        //  console.log(err.response)
        });
  }

  function getData(){
    axios.get(baseurl+"/api/v1/clients")
    .then((response)=>{
      setClients(response.data);
      //console.log(response.data);
    });
  }

  useEffect(() => {
    const admin = window.location.pathname.split('/')[2];
    if(admin === 'add'){
      VaryingContentonetoggle();
    }
    getData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
 },[])

  return (
    <Fragment>
    <Breadcrumb parent="Admin" title="Gestion des Clients" />
    <Container fluid={true}>
      <Row className="p-20">
        <Col xl="9">
        <Button
          onClick={VaryingContentonetoggle}
          className="btn btn-air-primary"
          outline
          color="primary"
        >
          {" "}
          <i className="icon-plus"></i> {" Ajouter un Client"}
        </Button>
        </Col>
        <Col xl="3 text-right">
        <Button
          onClick={()=>handelExcel(Clients)}
          className="btn btn-air-primary"
          outline
          color="primary"
        >
          {" "}
          <i className="icon-download"></i> {" Exporter en Excel"}
        </Button>
        </Col>
      
      
        
        {/* <CSVLink data={Clients} style={{margin: "0 10px"}} filename="clients" className="btn btn-air-primary btn-outline-primary" >{" Exporter en Excel"}</CSVLink>
        {/*<ExportExcel excelData={Clients} fileName={"Clients"} />*/}

        <Modal isOpen={VaryingContentone} toggle={VaryingContentonetoggle} size="lg">
          <ModalHeader toggle={VaryingContentonetoggle}>
            {"Ajouter un Client"}
          </ModalHeader>
          <ModalBody>
            <Form>
              <Row>
              <FormGroup className="col-md-6">
                <Label className="col-form-label" for="recipient-name">
                  {"Nom / Raison sociale *"}
                </Label>
                <Input
                    name="name"
                    type="text"
                    value={state.name}
                    required={true}
                    onChange={handleChange}
                  />
              </FormGroup>
              <FormGroup className="col-md-6">
                <Label className="col-form-label" for="recipient-name">
                  {"Nom / Prénom *"}
                </Label>
                <Input
                    name="prenom"
                    type="text"
                    value={state.prenom}
                    required={true}
                    onChange={handleChange}
                  />
              </FormGroup>
              </Row>
            <Row>
              <FormGroup className="col-md-6">
                <Label className="col-form-label" for="recipient-name">
                  {"Fonction *"}
                </Label>
                <Input
                    name="fonction"
                    type="text"
                    value={state.fonction}
                    required={true}
                    onChange={handleChange}
                  />
              </FormGroup>
              <FormGroup className="col-md-6">
                <Label className="col-form-label" for="recipient-name">
                  {"Adresse *"}
                </Label>
                <Input
                    name="adresse"
                    type="text"
                    value={state.adresse}
                    required={true}
                    onChange={handleChange}
                  />
              </FormGroup>
              </Row>
              <Row>
              <FormGroup className="col-md-6">
                <Label className="col-form-label" for="recipient-name">
                  {"Ville *"}
                </Label>
                <Input
                    name="ville"
                    type="text"
                    value={state.ville}
                    required={true}
                    onChange={handleChange}
                  />
              </FormGroup>
              <FormGroup className="col-md-6">
                <Label className="col-form-label" for="recipient-name">
                  {"Pays *"}
                </Label>
                <Input
                    name="pays"
                    type="text"
                    value={state.pays}
                    required={true}
                    onChange={handleChange}
                  />
             
              </FormGroup>
              </Row>
              <FormGroup>
                <Label className="col-form-label" for="recipient-name">
                  {"ICE Client *"}
                </Label>
                <Input
                    name="ice"
                    type="text"
                    value={state.ice}
                    required={true}
                    onChange={handleChange}
                  />
              </FormGroup>
              <Row>
              <FormGroup className="col-md-6">
                <Label className="col-form-label" for="recipient-name">
                  {"Téléphone *"}
                </Label>
                <Input
                    name="tel"
                    type="text"
                    value={state.tel}
                    required={true}
                    onChange={handleChange}
                  />
              </FormGroup>
              <FormGroup className="col-md-6">
                <Label className="col-form-label" for="recipient-name">
                  {"Email *"}
                </Label>
                <Input
                    name="email"
                    type="email"
                    value={state.email}
                    required={true}
                    onChange={handleChange}
                  />
             
              </FormGroup>
              </Row>
              <FormGroup>
                <Label className="col-form-label" for="recipient-name">
                  {"Activité du client *"}
                </Label>
                <Input
                    name="activite"
                    type="text"
                    value={state.activite}
                    required={true}
                    onChange={handleChange}
                  />
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label" for="recipient-name">
                  {"Mot de passe *"}
                </Label>
                <Input
                    name="pwd"
                    type="text"
                    value={state.pwd}
                    disabled={state.de}
                    required={true}
                    onChange={handleChange}
                  />
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label" for="recipient-name">
                  {"Activé *"}
                </Label>
            <Row className="col-sm-12 mega-inline p-20">
                  <Col sm="6">
                      <Card style={{border:"0px"}}>
                        <Media className="p-10">
                          <div className="radio radio-primary mr-3">
                            <Input onChange={(e) => handleChange(e)} id="active" type="radio" name="active" value={1} checked={state.active === 1}/>
                            <Label for="active"></Label>
                          </div>
                          <Media body style={{flex:0}}>
                           <span className="badge badge-primary pull-right digits">{"Activer"}</span>
                          </Media>
                        </Media>
                      </Card>
                    </Col>
                    <Col sm="6">
                      <Card style={{border:"0px"}}>
                        <Media className="p-10">
                          <div className="radio radio-secondary mr-3">
                            <Input onChange={(e) => handleChange(e)} id="cancel" type="radio" name="active" value={0} checked={state.active === 0} />
                            <Label for="cancel"></Label>
                          </div>
                          <Media body style={{flex:0}}>
                            <span className="badge badge-secondary pull-right digits">{"Désactiver"}</span>
                          </Media>
                        </Media>
                      </Card>
                    </Col>
                  </Row>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={VaryingContentonetoggle}>
              {"Fermer"}
            </Button>
            <Button color="primary" onClick={modif ? modifyitem : handleSubmit} >
              {modif? "Modifier" : "Ajouter"}
            </Button>
          </ModalFooter>
        </Modal>
      </Row>

      <Row>
        <Col sm="12">
          <Card>
            <div className="table-responsive">
              <Table className="table-border-horizontal">
                <thead>
                  <tr>
                    <th className="text-center">{"#"}</th>
                    <th className="col-md-6">{"Name"}</th>
                    <th className="text-center">{"Activé"}</th>
                    <th className="text-center">{"Date"}</th>
                    <th className="text-center">{"Action"}</th>
                  </tr>
                </thead>
                <tbody>
                  {Clients.map((cl, index) => (
                    <tr key={index} data-index={index}>
                      <th className="text-center" scope="row">{index+1}</th>
                      <td>{cl.name}</td>
                      <td className="text-center"> <span className={cl.active ? "badge rounded-pill badge-light-primary" : "badge rounded-pill badge-light-secondary"}>{cl.active ? "Activé" : "Desactivé"}</span> </td>
                      <td className="text-center">{cl.created.split('T')[0]}</td>
                      <td className="text-center">
                      <button className="btn btn-edit" style={{color:'#49b85a'}} onClick={()=>VaryingContentonetogglex(cl.name,cl.email,cl.pwd,cl.prenom,cl.fonction,cl.telephone,cl.adresse,cl.pays, cl.ville,cl.ice,cl.activite,cl.active,cl.uuid)}>
                        <i className="icon-pencil-alt"></i>
                      </button>
                      <button className="btn btn-edit" style={{color:'#f93e3e'}}  onClick={()=>Verticalcentermodaltoggle(index)}>
                        <i className="icon-trash"></i>
                      </button>
                      <Modal
                              isOpen={Verticalcenter === index}
                              toggle={Verticalcentermodaltoggle}
                              centered
                            >
                              <ModalHeader toggle={Verticalcentermodaltoggle}>
                                {"Confirmez-vous la suppression ?"}
                              </ModalHeader>
                              <ModalBody>
                                <p style={{textAlign:'center',marginLeft: '70px',marginRight:'70px', marginTop:'10px'}}>
                                  {
                                    "Si vous confirmez, le client sera définitivement supprimé de la base"
                                  }
                                </p>
                              </ModalBody>
                              <ModalFooter>
                              <Button
                                  color="primary"
                                  onClick={(e) => {
                                    deleteitem(cl.uuid,e);
                                  }}
                                >
                                  {"Je confirme"}
                                </Button>

                                <Button
                                  color="secondary"
                                  onClick={Verticalcentermodaltoggle}
                                >
                                  {"Annuler"}
                                </Button>
                                
                              </ModalFooter>
                            </Modal>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div className="table-responsive product-table">
  
              </div>
            </div>
          </Card>
        </Col>
      </Row>
     
    </Container>
  </Fragment>
  );
};

export default Clients;
