import React, { Fragment, useState, useEffect } from 'react';
import {LogIn, MessageSquare,ShoppingCart, Minus, Plus, X } from 'react-feather';
import { useHistory } from 'react-router-dom'

//import {useAuth0} from '@auth0/auth0-react'
import { User,Home} from 'react-feather'

import {Link} from 'react-router-dom'
import {
  setTranslations,
  setDefaultLanguage,
  setLanguageCookie,
  //setLanguage,
  translate,
} from 'react-switch-lang';
import  secureLocalStorage  from  "react-secure-storage";


import en from '../../assets/i18n/en.json';
import es from '../../assets/i18n/es.json';
import pt from '../../assets/i18n/pt.json';
import fr from '../../assets/i18n/fr.json';
import du from '../../assets/i18n/du.json';
import cn from '../../assets/i18n/cn.json';
import ae from '../../assets/i18n/ae.json';
import { InputGroup, Button } from 'reactstrap';

setTranslations({ en, es, pt, fr, du, cn, ae });
setDefaultLanguage('en');
setLanguageCookie();

const Rightbar = (props) => {    

  const [side,SetSide]=useState(false);
  const history = useHistory();
  //const [profile, setProfile] = useState('');
  const [name, setName] = useState('')
 // const [searchresponsive, setSearchresponsive] = useState(false)
  //const [langdropdown, setLangdropdown] = useState(false)
  //const [moonlight, setMoonlight] = useState(false)
  //const [selected, setSelected] = useState("fr")
  const [cartDropdown, setCartDropDown] = useState(false)
  //const [notificationDropDown, setNotificationDropDown] = useState(false)
  const [chatDropDown, setChatDropDown] = useState(false)
  
  // auth0 profile
  //const {logout} = useAuth0()
  const authenticated = JSON.parse(localStorage.getItem("authenticated"));
  const auth0_profile = JSON.parse(localStorage.getItem("auth0_profile"))

 /* const handleSetLanguage = (key) => {
    setLanguage(key);
    setSelected(key)
  };*/

  useEffect(() => {
    //setProfile(localStorage.getItem('profileURL') || man);
    setName(secureLocalStorage.getItem('name'))
    if(secureLocalStorage.getItem("layout_version") === "dark-only"){
      //setMoonlight(true)
    }
    const admin = window.location.pathname.split('/')[1];
    if(admin === 'admin'){
      SetSide(true);
    }
  }, []);



 

  const RedirectToCart = () => {
    history.push(`${process.env.PUBLIC_URL}/app/ecommerce/cart`)
  }

 

  //full screen function
 /* function goFull() {
    if ((document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }*/

  /*const SeacrhResposive = (searchresponsive) => {
    if (searchresponsive) {
      setSearchresponsive(!searchresponsive)
      document.querySelector(".search-full").classList.add("open");
      //document.querySelector(".more_lang").classList.remove("active");
    } else {
      setSearchresponsive(!searchresponsive)
      document.querySelector(".search-full").classList.remove("open");
    }
  }*/

 /* const LanguageSelection = (language) => {
    if (language) {
      setLangdropdown(!language)
    } else {
      setLangdropdown(!language)
    }
  }*/

  /*const MoonlightToggle = (light) => {
    if (light) {
      //setMoonlight(!light)
      document.body.className = "light"
      localStorage.setItem('layout_version', 'light');
    } else {
      //setMoonlight(!light)
      document.body.className = "dark-only"
      localStorage.setItem('layout_version', 'dark-only');
    }
  }*/

  const LogOut = () => {
      secureLocalStorage.clear();
      window.location.reload(false);
  }
  const navigate = useHistory();

  const goto = (url) =>{
    navigate.push(url);
  }

  return (
    <Fragment>
      <div className="nav-right col-8 pull-right right-header p-0">
        <ul className="nav-menus mrgau">
 
          <li className="cart-nav onhover-dropdown" style={{display:"none"}}>
            <div className="cart-box" onClick={() => setCartDropDown(!cartDropdown)}><ShoppingCart/><span className="badge badge-pill badge-primary">{"2"}</span></div>
            <ul className={`cart-dropdown onhover-show-div ${cartDropdown ? "active" : ""}`}>
              <li>
                <h6 className="mb-0 f-20">{"ShopingBag"}</h6><ShoppingCart/>
              </li>
              <li className="mt-0">
                <div className="media" onClick={RedirectToCart}><img className="img-fluid rounded-circle mr-3 img-60" src={require("../../assets//images/widget-bg.png")} alt=""/>
                  <div className="media-body"><span>{"V-Neck Shawl Collar Woman's Solid T-Shirt"}</span>
                    <p>{"Yellow(#fcb102)"}</p>
                    <div className="qty-box">
                      <InputGroup>
                              <button className="btn quantity-left-minus" type="button" data-type="minus" data-field=""><Minus/></button>
                            <input className="form-control input-number" type="text" name="quantity" defaultValue="1"/>
                              <button className="btn quantity-right-plus" type="button" data-type="plus" data-field=""><Plus/></button>
                      </InputGroup>
                    </div>
                    <h6 className="text-right text-muted">{"$299.00"}</h6>
                  </div>
                  <div className="close-circle"><a href="#javascript"><X/></a></div>
                </div>
              </li>
              <li className="mt-0">
                <div className="media" onClick={RedirectToCart}><img className="img-fluid rounded-circle mr-3 img-60" src={require("../../assets//images/widget-bg.png")} alt=""/>
                  <div className="media-body"><span>{"V-Neck Shawl Collar Woman's Solid T-Shirt"}</span>
                    <p>{"Yellow(#fcb102)"}</p>
                    <div className="qty-box">
                      <InputGroup>
                            <button className="btn quantity-left-minus" type="button" data-type="minus" data-field=""><Minus/></button>
                          <input className="form-control input-number" type="text" name="quantity" defaultValue="1"/>
                            <button className="btn quantity-right-plus" type="button" data-type="plus" data-field=""><Plus/></button>
                      </InputGroup>
                    </div>
                    <h6 className="text-right text-muted">{"$299.00"}</h6>
                  </div>
                  <div className="close-circle"><a href="#javascript"><X/></a></div>
                </div>
              </li>
              <li>
                <div className="total">
                  <h6 className="mb-2 mt-0 text-muted">{"OrderTotal"} : <span className="f-right f-20">{"$598.00"}</span></h6>
                </div>
              </li>
              <li>
                <Link to={`${process.env.PUBLIC_URL}/app/ecommerce/product`}><Button color="primary" className="btn btn-block view-cart">{"GoToShopingBag"}</Button></Link>
                <Link to={`${process.env.PUBLIC_URL}/app/ecommerce/checkout`}><Button color="secondary" className="btn-block view-cart mt-2">{"CheckOut"}</Button></Link>
              </li>
            </ul>
          </li>
          <li className="onhover-dropdown" onClick={() => setChatDropDown(!chatDropDown)} style={{display:"none"}}><MessageSquare/>
            <ul className={`chat-dropdown onhover-show-div ${chatDropDown ? "active" : ""}`}>
              <li>
                <MessageSquare />
                <h6 className="f-18 mb-0">{"MessageBox"}</h6>
              </li>

           
              <li className="text-center"> <button className="btn btn-primary">{"ViewAll"} </button></li>
            </ul>
          </li>
         {/* <li className={side ? "hide":""} ><span className="header-search"><Search onClick={() => SeacrhResposive(searchresponsive)} /></span></li>
          <li className="maximize"><a className="text-dark" href="#javascript" onClick={goFull}><Minimize /></a></li>*/}
          <li className={side ? "hide":""} ><button className="btn-none" onClick={()=>goto("/")}> <Home color='#49b859' size={40} /></button></li>
          <li className="profile-nav onhover-dropdown p-0">
            <div className="media profile-media">
            <User color='#49b859' size={40}/>
              {/*<img className="b-r-10" src={authenticated ? auth0_profile.picture : profile} alt="" />*/}
              <div className="media-body"><span>{authenticated ? auth0_profile.name :  name}</span>
                <p className="mb-0 font-roboto">{"Paramètres"} <i className="middle fa fa-angle-down"></i></p>
              </div>
            </div>
            <ul className="profile-dropdown onhover-show-div" style={{width:"235px"}}>
              {/*<li onClick={() => UserMenuRedirect(`${process.env.PUBLIC_URL}/app/users/userProfile`)}><User /><span>{Account} </span></li>
              <li onClick={() => UserMenuRedirect(`${process.env.PUBLIC_URL}/app/email-app`)}><Mail /><span>{Inbox}</span></li>
              <li onClick={() => UserMenuRedirect(`${process.env.PUBLIC_URL}/app/todo-app/todo`)}><FileText /><span>{Taskboard}</span></li>*/}
              <li onClick={LogOut}><LogIn /><span>{"Se déconnecter"}</span></li>
              {/*<li ><Edit2 /><span>{"Changer le mot de passe"}</span></li>*/}
            </ul>
          </li>
          <li className={side ? "hide":""}><button className='btn btn-outline-primary btn-sm'  onClick={()=>goto("/contact")}> {'Nous Contacter'} </button></li>
        </ul>
      </div>
    </Fragment>

  );
}
export default translate(Rightbar);