import {Home,Users,Book,Layers,ShoppingBag,RotateCcw} from 'react-feather'
export const MENUITEMSAD = [
    {
        menutitle:"",
        menucontent:"",
        Items:[
            { title: 'Dashboard', path: '/admin/dashboard',icon: Home,  type: 'link',active:false},
            { 
                title: 'Gestion des clients', icon: Users, type: 'sub',active: false, children: [
                    { path: `/admin/add/client`, title: 'Ajouter un client', type: 'link' },
                    { path: `/admin/clients`, title: 'Liste des clients', type: 'link' },
                    { path: `/admin/assign`, title: 'Assigner un produit', type: 'link' },

                ],
            },
            {
                title: 'Gestion des produits', icon: ShoppingBag, type: 'sub',active: false, children: [
                    { path: `/admin/add/product`, title: 'Ajouter une produit', type: 'link' },
                    { path: `/admin/products`, title: 'Liste des produits', type: 'link' },
                    { path: `/admin/overview`, title: 'Liste des aperçus', type: 'link' },
                    { path: `/admin/specs`, title: 'Liste des spécifications', type: 'link' },


                ],
            },
            {    title: 'Gestion des catégories', icon: Layers, type: 'sub',active: false, children: [
                    { path: `/admin/add/categorie`, title: 'Ajouter une catégorie', type: 'link' },
                    { path: `/admin/categories`, title: 'Liste des catégories', type: 'link' },

                ],
            },
            {    title: 'Gestion des documents', icon: Book, type: 'sub',active: false, children: [
                    { path: `/admin/add/document`, title: 'Ajouter un document', type: 'link' },
                    { path: `/admin/documents`, title: 'Liste des documents', type: 'link' },

                ],
            }, 

            {    title: 'Historique', icon:RotateCcw, type: 'sub',active: false, children: [
                { path: `/admin/restore/product`, title: 'Restaurer un Produit', type: 'link' },
                { path: `/admin/restore/client`, title: 'Restaurer un Client', type: 'link' },
                { path: `/admin/restore/categorie`, title: 'Restaurer une Catégorie', type: 'link' },

            ],
        }, 
        ]
    },
    
]