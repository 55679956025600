import React, { Fragment,useEffect, useState} from "react";
import {
  Row,Col,Card,CardBody,CardHeader,Table,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import {File,Users,ShoppingBag,List} from "react-feather";
import secureLocalStorage from "react-secure-storage";
import axios from "axios"
import { Link } from "react-router-dom";

const Dashboard = () => {
  const [statscl, setStatscl] = useState(0);
  const [statsctg, setStatsctg] = useState(0);
  const [statspr, setStatspr] = useState(0);
  const [statsdoc, setStatsdoc] = useState(0);
  const [contacts, setContacts] = useState([]);

  const baseurl = "https://d3c9ouasuy8pg6.cloudf.almaxyra.ma";
  const t  = secureLocalStorage.getItem('token');
  const headers = {'x-access-token': t}

  const [Verticalcenter, setVerticalcenter] = useState(-1);
  function Verticalcentermodaltoggle(index) {
    setVerticalcenter(index);
  }


function getData(){


    axios.get(baseurl+"/api/v1/stats",{headers:headers})
    .then((response)=>{
      setStatscl(response.data.clients[0].count);
      setStatsctg(response.data.categories[0].count);
      setStatspr(response.data.products[0].count);
      setStatsdoc(response.data.documents[0].count);
    });
  }

  function getContact(){
    axios.get(baseurl+"/api/v1/contact",{headers:headers})
    .then((response)=>{
      setContacts(response.data);
    });

  }
useEffect(() => {
    getData();
    getContact();
      // eslint-disable-next-line react-hooks/exhaustive-deps
 },[])
  return (
    <Fragment>
      <Col xl="12" lg="6" md="7" style={{ padding: "20px 10px" }}>
        <div className="faq-title">
          <h1>{"Dashboard"}</h1>
        </div>
      </Col>
    <Row>
    <Col xl="3" className="chart_data_right box-col-12">
          <Card><Link to="/admin/clients/">
              <CardBody>
                <div className="media align-items-center">
                  <div className="media-body right-chart-content">
                    <h4>{statscl}</h4><span>{"Clients"}</span>
                  </div>
                  <div className="knob-block text-center">
                    <Users size={30} color={"#49b859"} />
                  </div>
                </div>
              </CardBody>
              </Link>
            </Card>
      
      </Col>
      <Col xl="3" className="chart_data_right box-col-12">
           <Card><Link to="/admin/products/">
              <CardBody>
                <div className="media align-items-center">
                  <div className="media-body right-chart-content">
                    <h4>{statspr}</h4><span>{"Produits"}</span>
                  </div>
                  <div className="knob-block text-center">
                    <ShoppingBag size={30} color={"#49b859"} />
                  </div>
                </div>
              </CardBody>
              </Link>
            </Card>
      </Col>
      <Col xl="3" className="chart_data_right box-col-12">
           <Card><Link to="/admin/categories/">
              <CardBody>
                <div className="media align-items-center">
                  <div className="media-body right-chart-content">
                    <h4>{statsctg}</h4><span>{"Catégories"}</span>
                  </div>
                  <div className="knob-block text-center">
                    <List size={30} color={"#49b859"} />
                  </div>
                </div>
              </CardBody>
              </Link>
            </Card>
      </Col>
      <Col xl="3" className="chart_data_right box-col-12">
         <Card><Link to="/admin/documents/">
              <CardBody>
                <div className="media align-items-center">
                  <div className="media-body right-chart-content">
                    <h4>{statsdoc}</h4><span>{"Documents"}</span>
                  </div>
                  <div className="knob-block text-center">
                    <File size={30} color={"#49b859"} />
                  </div>
                </div>
              </CardBody>
              </Link>
            </Card>
      </Col>
      <Col sm="12">
          <Card>
            <div className="table-responsive">
              <Table className="table-border-horizontal">
                <thead>
                  <tr>
                    <th>{"#"}</th>
                    <th >{"Nom"}</th>
                    <th >{"Entreprise"}</th>
                    <th >{"Email"}</th>
                    <th >{"Téléphone"}</th>
                    <th >{"Objet"}</th>
                    <th>{"Date"}</th>
                    <th >{"Action"}</th>
                  </tr>
                </thead>
                <tbody>
                  {contacts ? 
                   contacts.map((doc, index) => (
                    <tr key={index} data-index={index}>
                      <th scope="row">{index+1}</th>
                      <td>{doc.nom}</td>
                      <td>{doc.entreprise}</td>
                      <td>{doc.email}</td>
                      <td>{doc.telephone}</td>
                      <td>{doc.objet !== "Autres" ? doc.objet : doc.autre}</td>
                      <td>{doc.created.split("T")[0]}</td>
                      <td>
                      <button className="btn btn-edit" style={{color:'#49b85a'}} onClick={()=>Verticalcentermodaltoggle(index)}>
                        <i className="icon-eye"></i>
                      </button>
                      <Modal
                              isOpen={Verticalcenter === index}
                              toggle={Verticalcentermodaltoggle}
                              centered
                            >
                              <ModalHeader toggle={Verticalcentermodaltoggle}>
                                {"Message Détaillé"}
                              </ModalHeader>
                              <ModalBody>
                                <p style={{textAlign:'center',marginLeft: '70px',marginRight:'70px', marginTop:'10px'}}>
                                  {
                                    doc.message
                                  }
                                </p>
                              </ModalBody>
                      </Modal>
                      </td>
                    </tr>
                  ))
                  : <tr key={111} data-index={111} ><td className="text-center" colSpan={8}>Aucune demande</td></tr>}
                </tbody>
              </Table>
            </div>
          </Card>
        </Col>

      <Col xl="4" className="news box-col-4 hide">
            <Card>
              <CardHeader>
                <div className="header-top">
                  <h5 className="m-0">{"Activité"}</h5>
                  <div className="card-header-right-icon">
                    <select className="button btn btn-primary">
                      <option>{"Today"}</option>
                      <option>{"Tomorrow"}</option>
                      <option>{"Yesterday"}</option>
                    </select>
                  </div>
                </div>
              </CardHeader>
              <CardBody className="p-0">
                <div className="news-update">
                  <h6>{"36% off For pixel lights Couslations Types."}</h6><span>{"Lorem Ipsum is simply dummy..."}</span>
                </div>
                <div className="news-update">
                  <h6>{"We are produce new product this"}</h6><span> {"Lorem Ipsum is simply text of the printing... "}</span>
                </div>
                <div className="news-update">
                  <h6>{"50% off For COVID Couslations Types."}</h6><span>{"Lorem Ipsum is simply dummy..."}</span>
                </div>
              </CardBody>
              <div className="card-footer">
                <div className="bottom-btn"><a href="#javascript">{"More..."}</a></div>
              </div>
            </Card>
          </Col>
    </Row>
    </Fragment>
  );
};

export default Dashboard;
