import React, { Fragment,useEffect,useState} from "react";
import { useHistory } from "react-router-dom";
import {
  Card,
  Col,
  Row,
  CardBody,
} from "reactstrap";
import axios from "axios"
import  secureLocalStorage  from  "react-secure-storage";


const Home = () => {
  const layoutColumns = 4;
  const history = useHistory();
  const [products,setProducts] = useState([]);
  const baseurl = "https://d3c9ouasuy8pg6.cloudf.almaxyra.ma";

  const goto = (id) =>{
    history.push('/products/'+id);
  }

  useEffect(() => {
    const t = secureLocalStorage.getItem("trole");
    //console.log(t);

    if(t === 1){
      window.location.href = `${process.env.PUBLIC_URL}/admin/dashboard/`;
    }

    const id = secureLocalStorage.getItem("uid");
    axios.get(baseurl+"/api/v1/myproducts/"+id)
    .then((response)=>{
      setProducts(response.data);
    });
}, [])

  return (
    <Fragment >
      <Col xl="12" lg="6" md="7"  style={{padding:'0px 60px', display:'none'}}>
            <div className="faq-title">
            <h6 className="fz-20">{"Dashboard"}</h6>
            </div>
        <Col xl="3" className="chart_data_right box-col-12" >
            <Card className="bg-img">
              <CardBody>
                <div className="media align-items-center">
                  <div className="media-body right-chart-content">
                    <h4>{"3"}<span className="new-box">{'Hot'}</span></h4><span>{'Mes Produits'}</span>
                  </div>
                  <div className="knob-block text-center">
                    <div className="knob1" id="ordervalue1"></div>
                  </div>
                </div>
              </CardBody>
            </Card>
        </Col>
      </Col>
      <Col xl="12" lg="6" md="7"  style={{padding:'0px 60px'}}>
          <div className="faq-title">
                <h6 className="fz-20">{"Mes Produits"}</h6>
          </div>
      </Col>
      <Col xl="10" lg="6" md="7" className='container'>
      <Row>
      {products ? products.map((item, i) =>
            <div className={`${layoutColumns === 3 ? 'col-xl-3 col-sm-6 xl-4 col-grid-box' : 'col-xl-' + layoutColumns}`} key={i}>
                <button className="btn-none"onClick={()=> goto(item.uuid)} style={{width:"390px"}}>
                    <Card>
                      <div className="product-box">
                        <div className="product-img">
                          {(item.status === 'sale') ?
                            <span className="ribbon ribbon-danger">
                              {item.status}
                            </span> : ''}
                          {(item.status === '50%') ?
                            <span className="ribbon ribbon-success ribbon-right">
                              {item.status}
                            </span> : ''}
                          {(item.status === 'gift') ?
                            <span className="ribbon ribbon-secondary ribbon-vertical-left">
                              <i className="icon-gift">{item.status}</i>
                            </span> : ''}
                          {(item.status === 'love') ?
                            <span className="ribbon ribbon-bookmark ribbon-vertical-right ribbon-info">
                              <i className="icon-heart">{item.status}</i>
                            </span> : ''}
                          {(item.status === 'Hot') ?
                            <span className="ribbon ribbon ribbon-clip ribbon-warning">
                              {item.status}
                            </span> : ''}
                          <img className="img-fluid w100" src={baseurl+"/getimage/" + item.image} alt="" />
                        </div>
                        <div className="line"></div>
                        <div className="product-details">
                          <h4  className="font-primary"  style={{fontWeight:'700'}}>{item.marque +' '+item.designation}</h4><br/>
                         {
                          // <p className="hidenumberlines">{item.description}</p>
                          }
                        </div>
                      </div>
                    </Card>
                    </button>
                  </div>
                ) : ''}
          </Row>
      </Col>

    </Fragment>
  );
};

export default Home;
